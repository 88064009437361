<template>
  <div class="columns share-buttons is-centered">
    <div class="column">
      <button
        class="button is-primary"
        @click="openSocialPopUp('https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl, 'facebook')"
      >
        <span class="icon">
          <i class="fab fa-facebook-f"></i>
        </span>
        <span>Udostępnij na facebooku</span>
      </button>
    </div>
    <div class="column">
      <button
        class="button is-primary"
        @click="openSocialPopUp('https://twitter.com/share?url=' + encodedUrl + '&text=' + encodedTitle, 'twitter')"
      >
        <span class="icon">
          <i class="fab fa-twitter"></i>
        </span>
        <span>Udostępnij na twitterze</span>
      </button>
    </div>
    <div class="column">
      <button
        class="button is-primary"
        @click="openSocialPopUp('https://www.linkedin.com/shareArticle?url=' + encodedUrl + '&title=' + encodedTitle, 'linkedin')"
      >
        <span class="icon">
          <i class="fab fa-linkedin-in"></i>
        </span>
        <span>Udostępnij na LinkedIn</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialShareButtons",
  props: ["url", "title"],
  methods: {
    openSocialPopUp(socialUrl, type) {
      console.log(socialUrl);
      var size = null;
      switch (type) {
        case "twitter":
          size = "width=600,height=300";
          break;
        case "facebook":
          size = "width=600,height=800";
          break;
        case "linkedin":
          size = "width=600,height=600";
          break;
        default:
          break;
      }
      window.open(socialUrl, "newwindow", size);
    }
  },
  computed: {
    encodedUrl() {
      return encodeURIComponent(this.url);
    },
    encodedTitle() {
      return encodeURIComponent(this.title);
    }
  }
};
</script>

<style>
.share-buttons {
  padding: 1.5rem;
}
</style>
