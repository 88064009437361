<template>
  <article class="media">
    <figure class="media-left">
      <p class="image resp-coin-image">
        <router-link
          :to="{name: 'detail', params: {slug: computedProduct.slug, weight: computedProduct.weight.replace('/', '_'), metal: computedProduct.metal == 'gold' ? 'zloto' : 'srebro'}}"
        >
          <picture>
            <source type="image/webp" :srcset="computedProduct.image_src_webp" />
            <img
              :src="computedProduct.image_src_png"
              :alt="'Zdjęcie przodu monety ' + computedProduct.name"
            />
          </picture>
        </router-link>
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <strong>{{ computedProduct.name }}</strong>
        <br />
        <div class="tags has-addons is-marginless">
          <span v-if="computedProduct.metal == 'gold'" class="tag is-warning is-marginless">ZŁOTO</span>
          <span v-if="computedProduct.metal == 'silver'" class="tag is-light is-marginless">SREBRO</span>
          <span class="tag is-light is-marginless">{{ computedProduct.weight }}</span>
        </div>
        <small class="has-text-grey">{{computedProduct.name_org}}</small>
        <br />
        <small class="has-text-grey">{{computedProduct.producer.name}}</small> &bull;
        <small class="has-text-grey">{{computedProduct.country}}</small>
        <br />
      </div>
      <div class="buttons">
        <router-link
          :to="{name: 'detail', params: {slug: computedProduct.slug, weight: computedProduct.weight.replace('/', '_'), metal: computedProduct.metal == 'gold' ? 'zloto' : 'srebro'}}"
          class="button is-primary"
        >
          <span class="icon">
            <i class="fas fa-info-circle" aria-hidden="true"></i>
          </span>
          <span>Zobacz szczegóły</span>
        </router-link>
        <router-link
          v-if="!isSide"
          :to="{name: 'verify', params: {slug: computedProduct.slug, weight: computedProduct.weight.replace('/', '_'), metal: computedProduct.metal == 'gold' ? 'zloto' : 'srebro'}}"
          class="button is-link is-outlined"
        >
          <span class="icon">
            <i class="fas fa-tasks" aria-hidden="true"></i>
          </span>
          <span>Zweryfikuj</span>
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "ProductWidget",
  props: ["product", "variant", "isSide"],
  computed: {
    computedProduct() {
      return this.mergeProductWithVariant(this.product, this.variant);
    },
  },
};
</script>

<style>
.resp-coin-image {
  max-width: 128px;
}

@media all and (max-width: 768px) {
  .resp-coin-image {
    max-width: 100px;
  }
}
</style>
