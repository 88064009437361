<template>
  <footer class="footer has-background-white">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <h3>Współpraca</h3>
        <ul>
          <li>
            <a
              href="https://cyberfaza.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Podcast CYBERFAZA o biznesie w internecie</a>
          </li>
          <li>
            <a
              href="https://asystentkowo.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Wirtualna Asystentka</a>
          </li>
          <li>
            <a
              href="https://nacocito.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Gadżety na każdą okazję</a>
          </li>

          <li>
            <a
              href="https://gseo.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Agencja Marketingu Cyfrowego GSEO</a>
          </li>

          <li>
            <a
              href="https://all4words.com"
              target="_blank"
              rel="noreferrer noopener"
            >Tłumaczenia online</a>
          </li>
          <li>
            <a
              href="https://nativeservice.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Tłumaczenie przysięgłe i tłumaczenie zwykłe</a>
          </li>
          <li>
            <a
              href="https://jakibroker.pl"
              target="_blank"
              rel="noreferrer noopener"
            >Jak wybrać brokera?</a>
          </li>
        </ul>
      </div>
      <div class="column">
        <h3>ZbierajMonety.pl</h3>
        <ul>
          <li>
            <a
              href="mailto:zbierajmonety@wonsystudio.com?subject=Błąd na stronie"
              class="link"
            >Zgłoś błąd na stronie</a>
          </li>
          <li>
            <a href="/static/Regulamin_zbierajmonety.pdf" class="link">Regulamin</a>
          </li>
          <li>
            <a
              href="/static/Polityka_Prywatnosci_zbierajmonety.pdf"
              class="link"
            >Polityka Prywatności</a>
          </li>
          <li>
            <a href="mailto:zbierajmonety@wonsystudio.com?subject=Reklama" class="link">Reklama</a>
          </li>
          <li>
            <router-link :to="{name: 'sitemap'}" class="link">Mapa Strony</router-link>
          </li>
        </ul>
      </div>
      <div class="column">
        <h3>Zobacz także</h3>
        <ul>
          <li>
            <a
              href="https://zbierajmonety.pl/kryzys-gospodarczy/#jak-przygotowa%C4%87-si%C4%99-na-kryzys%3F"
            >Jak przygotować się na kryzys gospodarczy?</a>
          </li>
          <li>
            <a
              href="https://zbierajmonety.pl/co-kupowac-sztabki-czy-monety/"
            >Co kupować złote sztabki czy monety?</a>
          </li>
          <li>
            <a
              href="https://zbierajmonety.pl/monety/zweryfikuj/kanadyjski-lisc-klonowy-1oz-zloto/#sprawdz-reakcje-na-magnes"
            >Czy magnes przyciąga monety?</a>
          </li>
          <li>
            <a href="https://zbierajmonety.pl">złoto cena</a>
          </li>
          <li>
            <a href="https://zbierajmonety.pl">srebro cena</a>
          </li>
          <li>
            <a href="https://zbierajmonety.pl">gdzie kupić złoto</a>
          </li>
          <li>
            <a href="https://zbierajmonety.pl">sztabka złota</a>
          </li>
          <li>
            <a
              href="https://zbierajmonety.pl/monety/?zloto=true&srebro=false"
            >złote monety inwestycyjne</a>
          </li>
          <li>
            <a
              href="https://zbierajmonety.pl/monety/?zloto=false&srebro=true"
            >srebrne monety lokacyjne</a>
          </li>
        </ul>
        <h3>Najpopularniejsze monety bulionowe</h3>
        <ul>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'krugerrand', weight: '1oz', metal: 'zloto'}}"
            >Krugerrand</router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'kanadyjski-lisc-klonowy', weight: '1oz', metal: 'zloto'}}"
            >Kanadyjski Liść Klonowy</router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'wiedenscy-filharmonicy', weight: '1oz', metal: 'zloto'}}"
            >Wiedeńscy Filharmonicy</router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'australijski-kangur', weight: '1oz', metal: 'zloto'}}"
            >Australijski Kangur</router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'amerykanski-orzel', weight: '1oz', metal: 'zloto'}}"
            >Amerykański Złoty Orzeł</router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'detail', params: {slug: 'libertad', weight: '1oz', metal: 'zloto'}}"
            >Libertad</router-link>
          </li>
        </ul>
      </div>

      <div class="column"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>
