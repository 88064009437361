<template>
  <div>
    <div class="dropdown is-large" :class="{'is-active': isDropdownActive}">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" @click="isDropdownActive = !isDropdownActive">
          <span v-if="product != null">
            {{product.name}}
            <template v-if="product.metal=='gold'">(Złoto)</template>
            <template v-if="product.metal=='silver'">(Srebro)</template>
          </span>
          <span v-else>
            <slot></slot>
          </span>
          <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="field">
          <div class="control has-icons-left">
            <input type="text" placeholder="np. Bizon" class="input" v-model="searchInput" />
            <span class="icon is-left">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
        <div class="dropdown-content">
          <article
            class="media dropdown-item cursor"
            v-for="product in sortedProducts"
            :key="product.slug + product.metal"
            @click="chooseProduct(product)"
          >
            <figure class="media-left">
              <p class="image is-48x48">
                <picture>
                  <source type="image/webp" :srcset="product.image_src_webp" />
                  <img :src="product.image_src_png" :alt="'Zdjęcie przodu monety ' + product.name" />
                </picture>
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <strong>{{ product.name }}</strong>
                <br />
                <div class="tags has-addons is-marginless">
                  <span v-if="product.metal == 'gold'" class="tag is-warning is-marginless">ZŁOTO</span>
                  <span v-if="product.metal == 'silver'" class="tag is-light is-marginless">SREBRO</span>
                </div>
                <small class="has-text-grey">{{product.name_org}}</small>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="control" v-if="product != null">
      <div class="select">
        <select @change="chooseVariant($event)">
          <option
            v-for="variant in product.variants"
            :key="variant.weight"
            :selected="variant.weight == '1oz'"
          >{{variant.weight}}</option>
        </select>
      </div>
    </div>
    <div v-else class="control">
      <div class="select">
        <select disabled>
          <option>Waga</option>
        </select>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ProductChoiceDropdown",
  props: ["products", "eventBus"],
  data() {
    return {
      searchInput: "",
      isDropdownActive: false,
      product: null,
    };
  },
  methods: {
    chooseProduct(product) {
      this.product = product;
      this.isDropdownActive = false;
      this.eventBus.$emit("PRODUCT_CHOSEN", product);
    },
    chooseVariant(event) {
      this.eventBus.$emit("VARIANT_CHOSEN", event.target.value);
    },
  },
  computed: {
    sortedProducts() {
      var toReturn = this.products;

      var self = this;
      if (this.searchInput != "") {
        toReturn = toReturn.filter(
          (item) =>
            item.name.toLowerCase().includes(self.searchInput.toLowerCase()) ||
            item.name_org.toLowerCase().includes(self.searchInput.toLowerCase())
        );
      }
      return toReturn;
    },
  },
};
</script>

<style scoped>
.dropdown-content {
  max-height: 14em;
  overflow: auto;
}
.cursor {
  cursor: pointer;
}
</style>
