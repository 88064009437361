<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <h1 class="title">Najnowsze artykuły</h1>
    <div class="columns is-multiline">
      <div class="column is-6" v-for="article in articles" :key="article.slug">
        <article-widget :article="article"></article-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrump from "./Breadcrump";

import articles from "../articles.json";

import ArticleWidget from "./ArticleWidget";

export default {
  name: "BlogArticleList",
  data() {
    return {
      title: "Baza Wiedzy o monetach inwestycyjnych",
      description:
        "Najnowsze artykuły o finansach i inwestowaniu w Monety Bulionowe",
      breadcrump: [
        {
          name: "Baza Wiedzy",
          to: {
            name: "articles",
          },
        },
      ],
    };
  },
  metaInfo() {
    return {
      // meta: [
      //   // general
      //   { name: "description", content: this.description },
      //   { name: "image", content: this.imageSrc },

      //   // Schema.org for Google
      //   { itemprop: "name", content: this.title },
      //   { itemprop: "description", content: this.description },
      //   { itemprop: "image", content: this.imageSrc },

      //   // twitter
      //   { name: "twitter:card", content: "summary_large_image" },
      //   { name: "twitter:title", content: this.title },
      //   { name: "twitter:description", content: this.description },
      //   { name: "twitter:image:src", content: this.imageSrc },

      //   // Open Graph general (Facebook, Pinterest & Google+)
      //   { property: "og:title", content: this.title },
      //   { property: "og:description", content: this.description },
      //   { property: "og:image", content: this.imageSrc },
      //   { property: "og:image:url", content: this.imageSrc },
      //   { property: "og:image:width", content: 650 * 2 },
      //   { property: "og:image:height", content: 341 * 2 },

      //   { property: "og:image:alt", content: this.title },
      //   { property: "og:url", content: this.urlContent },
      //   { property: "og:locale", content: "pl_PL" },
      //   { property: "og:type", content: "link" }

      //   // Open Graph - Product (e-commerce)
      //   // { property: 'product:price:currency', content: 'PLN'},
      //   // { property: 'product:price:amount', content: '6337.16'},
      //   // { property: 'product:brand', content: 'United States Mint'},
      // ],
      title: this.title,
      description: this.description,
    };
  },
  computed: {
    articles() {
      return articles.filter((art) => art.isPublic);
    },
  },
  components: {
    ArticleWidget,
    Breadcrump,
  },
};
</script>
