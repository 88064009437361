<template>
  <div class="buybox-widget">
    <table class="table is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Sklep</th>
          <th>Format</th>
          <th>Cena</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="offer in sortedOfferList"
          :key="offer.shopId"
          class="bb-store"
          @click="openLinkInNewTab(offer.goToStoreURL)"
        >
          <td>
            <figure class="image is-16x16">
              <img :src="offer.icon" :alt="'Logo sklepu internetowego ' + offer.name" />
            </figure>
            {{ offer.name }}
          </td>
          <td class="bb-type">{{ offer.type }}</td>
          <td class="bb-price">{{ offer.price }} zł</td>
          <td class="bb-go-to-store">
            <a
              :href="offer.goToStoreURL"
              class="button is-link"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <span>Idź do sklepu</span>
              <span class="icon">
                <i class="fas fa-external-link-alt" aria-hidden="true"></i>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "BuyBoxWidget",
  props: ["bbId", "bookNumber"],
  data() {
    return {
      offerList: []
    };
  },
  methods: {
    openLinkInNewTab(url) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  },
  computed: {
    sortedOfferList() {
      return this.offerList.sort(
        (entry1, entry2) => parseFloat(entry1.price) >= parseFloat(entry2.price)
      );
    }
  },
  created() {
    if (this.offerList.length == 0) {
      var self = this;
      fetch(
        `https://buybox.click/${this.bbId}/buybox.json?number=${this.bookNumber}`,
        { cache: "force-cache" }
      )
        .then(response => response.json())
        .then(data => {
          var offerList = [];
          for (let goToStoreURL in data.data) {
            let entry = data.data[goToStoreURL];
            offerList.push({
              ...entry,
              goToStoreURL
            });
          }
          self.offerList = offerList;
        });
    }
  }
};
</script>

<style>
.bb-store {
  cursor: pointer;
}
@media only screen and (max-width: 719px) {
  .buybox-widget {
    margin: 10px 0;
  }
  .buybox-widget .bb-store {
    width: 50%;
  }
  .buybox-widget .bb-type {
    width: 0%;
  }
  .buybox-widget .bb-price {
    width: 50%;
  }
  .buybox-widget .bb-go-to-store {
    display: none;
  }
}
</style>
