<template>
  <div>
    <button
      class="button is-fullwidth is-primary"
      @click="askMicrophonePermission"
      :class="{'is-loading': microphoneState == 'WAITING'}"
      :disabled="microphoneState == 'PERMITTED'"
    >
      <span class="icon">
        <i class="fas fa-microphone"></i>
      </span>
      <template v-if="microphoneState == 'NOT_PERMITTED'">
        <span>Włącz mikrofon</span>
        <span class="icon">
          <i class="fas fa-times"></i>
        </span>
      </template>
      <template v-if="microphoneState == 'PERMITTED'">
        <span>Mikrofon włączony</span>
        <span class="icon">
          <i class="fas fa-check"></i>
        </span>
      </template>
    </button>
    <hr />
    <canvas id="canvas" width="1000" height="600"></canvas>
    <nav class="level is-mobile">
      <div class="level-item has-text-centered">
        <div>
          <p
            :class="{'has-text-success': top1FrequencyDifference != undefined, 'has-text-danger': top1FrequencyDifference === undefined}"
            class="title is-size-5"
          >{{ top1 }} Hz</p>
          <p
            :class="{'has-text-success': top1FrequencyDifference != undefined, 'has-text-danger': top1FrequencyDifference === undefined}"
            class="subtitle is-size-6"
          >{{ top1FrequencyDifference }}%</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p
            :class="{'has-text-success': top2FrequencyDifference != undefined, 'has-text-danger': top2FrequencyDifference === undefined}"
            class="title is-size-5"
          >{{ top2 }} Hz</p>
          <p
            :class="{'has-text-success': top2FrequencyDifference != undefined, 'has-text-danger': top2FrequencyDifference === undefined}"
            class="subtitle is-size-6"
          >{{ top2FrequencyDifference }}%</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p
            :class="{'has-text-success': top3FrequencyDifference != undefined, 'has-text-danger': top3FrequencyDifference === undefined}"
            class="title is-size-5"
          >{{ top3 }} Hz</p>
          <p
            :class="{'has-text-success': top3FrequencyDifference != undefined, 'has-text-danger': top3FrequencyDifference === undefined}"
            class="subtitle is-size-6"
          >{{ top3FrequencyDifference }}%</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p
            :class="{'has-text-success': top4FrequencyDifference != undefined, 'has-text-danger': top4FrequencyDifference === undefined}"
            class="title is-size-5"
          >{{ top4 }} Hz</p>
          <p
            :class="{'has-text-success': top4FrequencyDifference != undefined, 'has-text-danger': top4FrequencyDifference === undefined}"
            class="subtitle is-size-6"
          >{{ top4FrequencyDifference }}%</p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
function sum(a) {
  return a.reduce((acc, val) => acc + val);
}

function mean(a) {
  return sum(a) / a.length;
}

function stddev(arr) {
  const arr_mean = mean(arr);
  const r = function(acc, val) {
    return acc + (val - arr_mean) * (val - arr_mean);
  };
  return Math.sqrt(arr.reduce(r, 0.0) / arr.length);
}

function smoothed_z_score(y, params, signals) {
  var p = params || {};
  // init cooefficients
  const lag = p.lag || 5;
  const threshold = p.threshold || 3.5;
  const influence = p.influece || 0.5;

  if (y === undefined || y.length < lag + 2) {
    throw ` ## y data array to short(${y.length}) for given lag of ${lag}`;
  }

  // init variables
  signals.fill(0);
  var filteredY = y.slice(0);
  const lead_in = y.slice(0, lag);

  var avgFilter = [];
  avgFilter[lag - 1] = mean(lead_in);
  var stdFilter = [];
  stdFilter[lag - 1] = stddev(lead_in);

  for (var i = lag; i < y.length; i++) {
    if (Math.abs(y[i] - avgFilter[i - 1]) > threshold * stdFilter[i - 1]) {
      if (y[i] > avgFilter[i - 1]) {
        signals[i] = +1; // positive signal
      } else {
        signals[i] = -1; // negative signal
      }
      // make influence lower
      filteredY[i] = influence * y[i] + (1 - influence) * filteredY[i - 1];
    } else {
      signals[i] = 0; // no signal
      filteredY[i] = y[i];
    }

    // adjust the filters
    const y_lag = filteredY.slice(i - lag, i);
    avgFilter[i] = mean(y_lag);
    stdFilter[i] = stddev(y_lag);
  }

  return signals;
}

export default {
  name: "PingSoundAnalyzer",
  props: ["product"],
  data() {
    return {
      top1: 0,
      top2: 0,
      top3: 0,
      top4: 0,
      now: null,
      elapsed: null,
      then: Date.now(),
      fps: 25,
      analyser: null,
      canvasCtx: null,
      dataArray: null,
      anyGreatedThanThresholdAtLeastOnce: false,
      signals: null,
      sampleRate: null,
      drawDetectedPeakScaleRatio: 0,
      // NOT_PERMITTED, WAITING, PERMITTED
      microphoneState: "NOT_PERMITTED"
    };
  },
  computed: {
    top1FrequencyDifference() {
      return this.getDifferenceFrequencyPercent(this.top1);
    },
    top2FrequencyDifference() {
      return this.getDifferenceFrequencyPercent(this.top2);
    },
    top3FrequencyDifference() {
      return this.getDifferenceFrequencyPercent(this.top3);
    },
    top4FrequencyDifference() {
      return this.getDifferenceFrequencyPercent(this.top4);
    }
  },
  methods: {
    getDifferenceFrequencyPercent(freq) {
      if (this.product === null) {
        return 0;
      }
      for (var idx in this.product.frequences) {
        let productFreq = this.product.frequences[idx];
        let difference = Math.abs(freq - productFreq);
        console.log("pFreq", productFreq, "freq", freq, "diff", difference);
        if (difference <= 100) {
          if (difference == 0) {
            return 0;
          }
          return parseFloat(100 - (productFreq / freq) * 100).toFixed(1);
        }
      }
    },
    askMicrophonePermission() {
      this.microphoneState = "WAITING";
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(this.startAnalyzer);
    },
    startAnalyzer(stream) {
      this.microphoneState = "PERMITTED";
      const canvas = document.getElementById("canvas");

      var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      this.sampleRate = audioCtx.sampleRate;
      let src = audioCtx.createMediaStreamSource(stream); // Give the audio context an audio source,

      this.analyser = audioCtx.createAnalyser();

      this.analyser.fftSize = 2048;
      // analyser.fftSize = 1024;

      var bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(bufferLength);
      this.drawDetectedPeakScaleRatio = this.dataArray.length / canvas.width;

      // Get a canvas defined with ID "oscilloscope"
      this.canvasCtx = canvas.getContext("2d");

      var biquadFilter = audioCtx.createBiquadFilter();
      biquadFilter.type = "highpass";
      // biquadFilter.frequency.setValueAtTime(2000, audioCtx.currentTime);
      biquadFilter.frequency.value = 100;

      biquadFilter.gain.setValueAtTime(100, audioCtx.currentTime);
      src.connect(biquadFilter);

      biquadFilter.connect(this.analyser);

      // Mute before going to speakers:
      var gainNode = audioCtx.createGain();
      gainNode.gain.setValueAtTime(0, audioCtx.currentTime);
      this.analyser.connect(gainNode);
      gainNode.connect(audioCtx.destination); // End destination of an audio graph in a given context
      // Sends sound to the speakers or headphones
      this.signals = new Uint8Array(this.dataArray.length);

      this.draw();
    },
    getFrequencyValue(binIndex, numberOfBins) {
      var nyquist = this.sampleRate / 2;
      var freq = binIndex * (nyquist / numberOfBins);
      return Math.round(freq);
    },
    drawLine(startX, startY, endX, endY) {
      this.canvasCtx.beginPath();
      // Staring point (10,45)
      this.canvasCtx.moveTo(startX, startY);
      // End point (180,47)
      this.canvasCtx.lineTo(endX, endY);
      // Make the line visible
      this.canvasCtx.strokeStyle = "rgb(0, 0, 0)";
      this.canvasCtx.stroke();
    },
    draw() {
      requestAnimationFrame(this.draw);

      // request another frame

      // calc elapsed time since last loop

      this.now = Date.now();
      this.elapsed = this.now - this.then;
      var fpsInterval = 1000 / this.fps;

      // if enough time has elapsed, draw the next frame
      if (this.elapsed > fpsInterval) {
        // Get ready for next frame by setting then=now, but also adjust for your
        // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
        this.then = this.now - (this.elapsed % fpsInterval);

        this.analyser.getByteFrequencyData(this.dataArray);
        let anyGreatedThanThreshold =
          this.dataArray.filter(value => value > 200).length > 0;

        if (anyGreatedThanThreshold) {
          this.anyGreatedThanThresholdAtLeastOnce = true;

          this.analyser.smoothingTimeConstant = 0.8;
          this.canvasCtx.fillStyle = "rgb(255, 255, 255)";
          this.canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

          this.canvasCtx.lineWidth = 2;
          this.canvasCtx.strokeStyle = "rgb(138, 77, 118)";

          this.canvasCtx.beginPath();

          var sliceWidth = (canvas.width * 1.0) / this.dataArray.length;
          var x = 0;

          // CALCULATE PEAKS
          smoothed_z_score(this.dataArray, {}, this.signals);
          var peaks = [];
          for (var idx in this.signals) {
            if (this.signals[idx] == 1) {
              peaks.push([
                idx,
                this.getFrequencyValue(idx, this.dataArray.length)
              ]);
            }
          }
          // END CALULATE PEAKS

          for (var i = 0; i < this.dataArray.length; i++) {
            var v = this.dataArray[i] / 128.0;
            var y = canvas.height - (v * canvas.height) / 2;

            if (i === 0) {
              this.canvasCtx.moveTo(x, y);
            } else {
              this.canvasCtx.lineTo(x, y);
            }

            x += sliceWidth;
          }

          this.canvasCtx.lineTo(canvas.width, canvas.height / 2);
          this.canvasCtx.stroke();
        } else if (this.anyGreatedThanThresholdAtLeastOnce) {
          // CALCULATE PEAKS
          smoothed_z_score(this.dataArray, {}, this.signals);
          var peaks = [];
          for (var idx in this.signals) {
            if (this.signals[idx] == 1) {
              peaks.push([
                idx,
                this.getFrequencyValue(idx, this.dataArray.length),
                this.dataArray[idx]
              ]);
            }
          }
          // END CALULATE PEAKS
          var alreadyDrawnPeaksRealIndexes = [];
          var drawnPeaksCounter = 0;
          let PeaksToDrawLimit = 4;
          var topPeaks = [];
          for (var i in peaks.sort((a, b) => a[2] < b[2])) {
            var realIndex = parseInt(peaks[i][0]);
            var peakAlreadyExist = false;
            for (var j in alreadyDrawnPeaksRealIndexes) {
              var existingRealIndex = alreadyDrawnPeaksRealIndexes[j];
              if (Math.abs(realIndex - existingRealIndex) < 100) {
                peakAlreadyExist = true;
                break;
              }
            }
            if (peakAlreadyExist || drawnPeaksCounter >= PeaksToDrawLimit) {
              continue;
            }
            this.drawLine(
              realIndex / this.drawDetectedPeakScaleRatio,
              0,
              realIndex / this.drawDetectedPeakScaleRatio,
              canvas.height
            );
            this.canvasCtx.font = "30px Nunito";
            this.canvasCtx.fillStyle = "black";
            this.canvasCtx.fillText(
              peaks[i][1] + " Hz",
              realIndex / this.drawDetectedPeakScaleRatio + 5,
              50 * (drawnPeaksCounter + 1)
            );
            drawnPeaksCounter += 1;
            alreadyDrawnPeaksRealIndexes.push(realIndex);
            topPeaks.push(peaks[i]);
          }
          topPeaks = topPeaks.sort((a, b) => a[0] > b[0]);
          this.top1 = topPeaks[0][1];
          this.top2 = topPeaks[1][1];
          this.top3 = topPeaks[2][1];
          this.top4 = topPeaks[3][1];
          this.anyGreatedThanThresholdAtLeastOnce = false;
        }
      }
    }
  }
};
</script>

<style>
#file-input {
  /* position: fixed; */
  top: 10px;
  left: 10px;
  z-index: 3;
}

#canvas {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 5px solid rgb(138, 77, 118);
}

audio {
  /* position: fixed; */
  left: 10px;
  bottom: 10px;
  width: calc(100% - 25px);
  z-index: 3;
}

#name {
  /* position: absolute; */
  top: 0;
  right: 20px;
  z-index: 3;
  color: #eeeeee;
  font-family: monospace;
}

#background-lol {
  width: 100%;
  height: 100%;
  /* position: fixed; */
  top: 0;
  left: 0;
  background-size: 100% 7px;
  z-index: 2;
  opacity: 0.3;
}
</style>
