<template>
  <div>
    <div>
      <section class="hero is-medium is-bold">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">ZbierajMonety.pl</h1>
            <h2 class="subtitle">Niezależny Portal o Inwestowaniu w Monety Bulionowe</h2>
          </div>
        </div>
      </section>
      <blog-article-template
        v-for="article in articles.slice(0,2)"
        :key="article.slug"
        :article="article"
        :isMinimal="true"
      ></blog-article-template>
      <top-bullion-coins-carousel></top-bullion-coins-carousel>
      <hr />

      <blog-article-template
        v-for="article in articles.slice(2)"
        :key="article.slug"
        :article="article"
        :isMinimal="true"
      ></blog-article-template>
    </div>
  </div>
</template>

<script>
import BlogArticleTemplate from "./BlogArticleTemplate";
import articles from "../articles.json";

export default {
  name: "HomePage",
  components: {
    BlogArticleTemplate,
  },
  computed: {
    articles() {
      return articles.filter((art) => art.isPublic);
    },
  },
  data() {
    return {
      title:
        "ZbierajMonety.pl - Niezależny Portal o Inwestowaniu w Monety Bulionowe",
      description:
        "Jak inwestować w metale szlachetne? Co lepsze sztabki czy monety? Co to jest patyna?",
      urlContent: "https://zbierajmonety.pl",
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        // { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        // { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        // { property: "og:image", content: this.imageSrc },
        // { property: "og:image:url", content: this.imageSrc },
        // { property: "og:image:width", content: 650 * 2 },
        // { property: "og:image:height", content: 341 * 2 },

        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ],
      title: this.title,
      description: this.description,
    };
  },
};
</script>
