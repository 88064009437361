import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta'

Vue.use(Meta)

import './assets/sass/styles.scss'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import zoom from 'vue-image-zoom';
import 'vue-image-zoom/dist/vue-image-zoom.css';

import ProductWidget from "./components/ProductWidget";
import TopBullionCoinsCarousel from "./components/TopBullionCoinsCarousel";
import VueJsonLD from 'vue-jsonld'

Vue.use(VueJsonLD)
Vue.use(zoom);
Vue.component('product-widget', ProductWidget)
Vue.component('top-bullion-coins-carousel', TopBullionCoinsCarousel)

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      get fbGroupMemebersCount() {
        return "2.8 tys.";
      },
      get isOffersOn() {
        return true;
      },
      get metalPL2ENG() {
        return {
          srebro: 'silver',
          srebrny: 'silver',
          złoto: 'gold',
          złoty: 'gold',
          miedź: 'copper',
        }
      },
      get metalENG2PL() {
        return {
          silver: 'srebro',
          gold: 'złoto',
          copper: 'miedź',
        }
      }
    }
  },
  methods: {
    mergeProductWithVariant(product, variant) {
      if (variant != null) {
        var variantToMerge = {};
        for (var idx in product.variants) {
          if (product.variants[idx].weight == variant) {
            variantToMerge = product.variants[idx];
            break;
          }
        }
        var merged = { ...product, ...variantToMerge };
        return merged;
      }
      return product;
    },
    addSpaceToWeight(weight) {
      return weight.split("oz")[0] + " oz"
    }
  },
})

const root = new Vue({
  render: h => h(App),
  router,
})


document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app')
  document.dispatchEvent(new Event('vue-post-render'))
})
