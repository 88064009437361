<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>

    <h1 class="title">Porównaj Monety</h1>
    <div class="columns">
      <div class="column is-5">
        <product-choice-dropdown
          :products="products"
          :eventBus="leftEventBus"
        >Wybierz pierwszą monetę</product-choice-dropdown>
      </div>

      <div class="column has-text-centered">VS.</div>

      <div class="column is-5">
        <product-choice-dropdown :products="products" :eventBus="rightEventBus">Wybierz drugą monetę</product-choice-dropdown>
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column is-half has-text-centered">
        <router-link
          v-if="compareLinkExists"
          class="button is-primary"
          :to="{name: 'compare', params: {slug1: leftProductSlug, weight1: leftProductWeight, metal1: leftProductChosen.metal == 'gold' ? 'zloto' : 'srebro', slug2: rightProductSlug, weight2: rightProductWeight, metal2: rightProductChosen.metal == 'gold' ? 'zloto' : 'srebro'}}"
        >
          <span class="icon is-small">
            <i class="fas fa-eye"></i>
          </span>
          <span>Porównaj</span>
        </router-link>

        <button v-else class="button is-primary" disabled>
          <span class="icon is-small">
            <i class="fas fa-eye"></i>
          </span>
          <span>Porównaj</span>
        </button>
      </div>
    </div>

    <h2 class="subtitle">Popularne porównania</h2>

    <div v-for="(product1, slug1) in products" :key="slug1">
      <h3 class="subtitle">{{product1.name}}</h3>
      <compare-widget
        v-for="(product2, slug2) in databaseWoSlug(slug1)"
        :key="'compare' + slug1 + slug2"
        :product1="product1"
        :product2="product2"
      ></compare-widget>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import json from "../database.json";
import ProductChoiceDropdown from "./ProductChoiceDropdown";
import CompareWidget from "./CompareWidget";
import Breadcrump from "./Breadcrump";

export default {
  name: "CompareChoice",
  data() {
    return {
      title: "Porównaj różne monety bulionowe",
      description: "Porównaj parametry różnych monet bulionowych.",
      products: json.sort(
        (product1, product2) => product1.name > product2.name
      ),
      searchInput: "",
      leftEventBus: new Vue(),
      rightEventBus: new Vue(),
      leftProductChosen: null,
      rightProductChosen: null,
      leftVariantChosen: "1oz",
      rightVariantChosen: "1oz",
      breadcrump: [
        {
          name: "Porównanie",
          to: {
            name: "comparechoice",
          },
        },
      ],
    };
  },
  metaInfo() {
    return {
      // meta: [
      //   // general
      //   { name: "description", content: this.description },
      //   { name: "image", content: this.imageSrc },

      //   // Schema.org for Google
      //   { itemprop: "name", content: this.title },
      //   { itemprop: "description", content: this.description },
      //   { itemprop: "image", content: this.imageSrc },

      //   // twitter
      //   { name: "twitter:card", content: "summary_large_image" },
      //   { name: "twitter:title", content: this.title },
      //   { name: "twitter:description", content: this.description },
      //   { name: "twitter:image:src", content: this.imageSrc },

      //   // Open Graph general (Facebook, Pinterest & Google+)
      //   { property: "og:title", content: this.title },
      //   { property: "og:description", content: this.description },
      //   { property: "og:image", content: this.imageSrc },
      //   { property: "og:image:url", content: this.imageSrc },
      //   { property: "og:image:width", content: 650 * 2 },
      //   { property: "og:image:height", content: 341 * 2 },

      //   { property: "og:image:alt", content: this.title },
      //   { property: "og:url", content: this.urlContent },
      //   { property: "og:locale", content: "pl_PL" },
      //   { property: "og:type", content: "link" }

      //   // Open Graph - Product (e-commerce)
      //   // { property: 'product:price:currency', content: 'PLN'},
      //   // { property: 'product:price:amount', content: '6337.16'},
      //   // { property: 'product:brand', content: 'United States Mint'},
      // ],
      title: this.title,
      description: this.description,
    };
  },
  mounted() {
    var self = this;
    this.leftEventBus.$on(
      "PRODUCT_CHOSEN",
      (product) => (self.leftProductChosen = product)
    );
    this.rightEventBus.$on(
      "PRODUCT_CHOSEN",
      (product) => (self.rightProductChosen = product)
    );
    this.leftEventBus.$on(
      "VARIANT_CHOSEN",
      (weight) => (self.leftVariantChosen = weight)
    );
    this.rightEventBus.$on(
      "VARIANT_CHOSEN",
      (weight) => (self.rightVariantChosen = weight)
    );
  },
  computed: {
    leftProductSlug() {
      if (this.compareLinkExists) {
        return this.leftProductChosen.slug;
      }
    },
    rightProductSlug() {
      if (this.compareLinkExists) {
        return this.rightProductChosen.slug;
      }
    },
    leftProductWeight() {
      if (this.compareLinkExists) {
        return this.leftVariantChosen.replace("/", "_");
      }
    },
    rightProductWeight() {
      if (this.compareLinkExists) {
        return this.rightVariantChosen.replace("/", "_");
      }
    },
    compareLinkExists() {
      if (this.leftProductChosen != null && this.rightProductChosen != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    databaseWoSlug(excludeSlug) {
      var ret = {};
      for (var slug in this.products) {
        if (excludeSlug != slug) {
          ret[slug] = this.products[slug];
        }
      }
      return ret;
    },
  },
  components: {
    ProductChoiceDropdown,
    CompareWidget,
    Breadcrump,
  },
};
</script>

<style>
.has-image-centered {
  margin-left: auto;
  margin-right: auto;
}
</style>
