<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <div class="content">
      <h1 class="title">Lista ofert sprzedaży monet</h1>
      <pricing-table></pricing-table>
    </div>
  </div>
</template>

<script>
import PricingTable from "./PricingTable";
import Breadcrump from "./Breadcrump";

export default {
  name: "OfferList",
  components: {
    PricingTable,
    Breadcrump
  },
  data() {
    return {
      breadcrump: [
        {
          name: "Oferty",
          to: {
            name: "offerlist"
          }
        }
      ]
    };
  }
};
</script>
