<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <div id="socialWindow">
      <h1 class="title">{{ product.name }}</h1>
      <h2 class="subtitle">od @{{ product.retailer.name }}</h2>

      <div class="container">
        <h3 class="subtitle">Zdjęcia</h3>
        <p>
          <small>Kliknij aby powiększyć</small>
        </p>
        <div class="columns is-multiline is-gapless is-mobile">
          <div class="column" v-for="(imageName, idx) in product.images" :key="imageName">
            <figure class="image is-256x256">
              <img
                :src="getImage(imageName)"
                :alt="'Zdjęcie ' + product.name + ' numer ' + idx"
                @click="zoomImage(getImage(imageName))"
              />
            </figure>
          </div>
        </div>
      </div>
      <h2 class="title has-text-primary is-pulled-right">{{product.price}}zł</h2>
    </div>
    <div class="container">
      <h3 class="subtitle">Parametry</h3>

      <table class="table is-borderless is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th class="has-text-right">Moneta</th>
            <th>
              <router-link
                :to="{name: 'detail', params: {slug: product.product_detail_page_slug, weight: product.product_detail_page_weight, metal: product.product_detail_page_metal}}"
              >
                <span class="icon">
                  <i class="fa fa-info-circle"></i>
                </span>
                <span>{{ product.product_detail_page_name }}</span>
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="has-text-right">Waga</td>
            <td>{{product.details.weight}}</td>
          </tr>

          <tr>
            <td class="has-text-right">Rok</td>
            <td>{{product.details.year}}</td>
          </tr>

          <tr>
            <td class="has-text-right">Wysyłka</td>
            <td>
              <span v-if="product.details.shipping" class="icon has-text-success">
                <i class="fas fa-check"></i>
              </span>
              <span v-else class="icon has-text-danger">
                <i class="fas fa-times"></i>
              </span>
            </td>
          </tr>

          <tr>
            <td class="has-text-right">Faktura VAT</td>
            <td>
              <span class="icon has-text-danger">
                <i class="fas fa-times"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="container offer-description">
      <h3 class="subtitle">Opis</h3>

      <article class="message is-primary">
        <div class="message-body content">
          <p>{{ product.description }}</p>
          <small>@{{ product.retailer.name }}</small>
        </div>
      </article>
    </div>

    <button class="button is-primary" @click="isMessageModalActive = true">
      <span class="icon">
        <i class="fa fa-envelope"></i>
      </span>
      <span>Napisz wiadomość</span>
    </button>

    <div class="modal" :class="{'is-active': isMessageModalActive}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Napisz do @{{product.retailer.name}}</p>
          <button class="delete" aria-label="close" @click="isMessageModalActive = false"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Podaj swój email</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="email" v-model.trim="messageEmail" />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <!--         <span class="icon has-text-success is-small is-right">
          <i class="fas fa-check"></i>
              </span>-->
            </div>
            <!-- <p class="help is-danger">This email is invalid</p> -->
          </div>

          <div class="field">
            <label class="label">Wiadomość</label>
            <div class="control">
              <textarea
                class="textarea"
                :placeholder="'np. Chętnie kupię ' + product.name"
                v-model="messageContent"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="messageAcceptTerms" />
                Znam
                <a href="#">regulamin</a>.
              </label>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="messageAcceptNewsletter" />
                Chcę zapisać się do
                <a href="#">newslettera</a>.
              </label>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="isMessageModalActive = false">Zamknij</button>
          <button
            class="button is-primary"
            @click="submitForm"
            :class="{'is-loading': messageSendButtonClicked}"
            :disabled="isMessageSent"
          >Wyślij</button>
          <span v-if="isMessageSent" class="has-text-success">Wiadomość wysłana!</span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrump from "./Breadcrump";
import offers from "../offers.json";

export default {
  name: "Offer",
  data() {
    return {
      product: null,
      title: "",
      description: "",
      imageSrc: "",
      urlContent: "",
      isMessageModalActive: false,
      isMessageSent: false,
      messageEmail: "",
      messageContent: "",
      messageAcceptTerms: false,
      messageAcceptNewsletter: false,
      messageSendButtonClicked: false
    };
  },
  methods: {
    getImage(imageName) {
      if (this.product.images.length != 0) {
        return `/images/${this.product.offer_id}/${imageName}`;
      }
    },
    zoomImage(imagePath) {
      this.$zoom(imagePath);
    },
    submitForm() {
      var self = this;
      this.messageSendButtonClicked = true;
      const url =
        "https://6qrfmr9391.execute-api.eu-central-1.amazonaws.com/Prod/submitForm";
      const data = {
        offer: this.$route.fullPath,
        email: this.messageEmail,
        content: this.messageContent,
        AcceptTerms: this.messageAcceptTerms,
        AcceptNewsletter: this.messageAcceptNewsletter
      };
      fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json"
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }).then(r => {
        self.messageSendButtonClicked = false;
        self.isMessageSent = true;
      });
    }
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.imageSrc },
        { property: "og:image:url", content: this.imageSrc },
        { property: "og:image:width", content: 650 * 2 },
        { property: "og:image:height", content: 341 * 2 },

        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "product" },

        // Open Graph - Product (e-commerce)
        { property: "product:price:amount", content: this.product.price },
        { property: "product:price:currency", content: "PLN" },
        {
          property: "product:brand",
          content: this.product.product_detail_page_name
        }
      ],
      title: this.title,
      description: this.description
    };
  },
  watch: {
    product() {
      if (this.product != null) {
        this.title = `${this.product.name} na sprzedaż`;
        this.description = `Oferta sprzedaży monety lokacyjnej ${this.product.product_detail_page_name} o wadze ${this.product.details.weight} od @${this.product.retailer.name}`;
        const currentAbsoluteUrl = new URL(window.location.href);
        // this.imageSrc = `${currentAbsoluteUrl.origin}/img${this.$route.path}${this.product.slug}.png`;
        this.imageSrc = `https://zbierajmonety.pl/img${this.$route.path}${this.product.offer_id}-${this.product.slug}.png`;

        // this.urlContent = window.location.href;
        this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;
      }
    }
  },
  beforeMount() {
    // prepare database:
    var database = {};
    for (var i in offers) {
      var elem = offers[i];
      database[elem.offer_id] = elem;
    }
    this.product = database[this.$route.params.offerid];
  },
  components: {
    Breadcrump
  },
  computed: {
    breadcrump() {
      return [
        {
          name: "Oferty",
          to: {
            name: "offerlist"
          }
        },
        {
          name: this.product.name,
          to: {
            name: "offer",
            params: {
              offerid: this.product.offer_id,
              slug: this.product.slug
            }
          }
        }
      ];
    }
  }
};
</script>
<style>
.offer-description {
  padding-bottom: 1rem;
}
</style>
