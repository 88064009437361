import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import BlogArticle from "./components/BlogArticle.vue"
import BlogArticleList from "./components/BlogArticleList.vue"
import CompareChoice from "./components/CompareChoice.vue"
import ComparisonDetail from "./components/ComparisonDetail"
import Detail from "./components/Detail.vue"
import HomePage from "./components/HomePage.vue"
import NotFound from "./components/NotFound.vue"
import Offer from "./components/Offer.vue"
import OfferList from "./components/OfferList.vue"
import ProductList from "./components/ProductList.vue"
import SiteMap from "./components/SiteMap.vue"
import SoundTestPage from "./components/SoundTestPage.vue"
import VerifyProduct from "./components/VerifyProduct.vue"

const routes = [
    {
        path: "/monety/porownanie/",
        name: "comparechoice",
        component: CompareChoice,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/monety/porownanie/:slug1+-:weight1(1_50oz|1_4oz|1_2oz|1_20oz|1_25oz|5oz|1oz|10oz|1_10oz|2oz)-:metal1(zloto|srebro)-vs-:slug2+-:weight2(1_50oz|1_4oz|1_2oz|1_20oz|1_25oz|5oz|1oz|10oz|1_10oz|2oz)-:metal2(zloto|srebro)/",
        name: "compare",
        component: ComparisonDetail,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/monety/",
        name: "productlist",
        component: ProductList,
        pathToRegexpOptions: { strict: true },
    },
    // {
    //     path: "/oferty/",
    //     name: "offerlist",
    //     component: OfferList,
    //     pathToRegexpOptions: { strict: true }
    // },
    // {
    //     path: "/oferty/:offerid+-:slug+/",
    //     name: "offer",
    //     component: Offer,
    //     pathToRegexpOptions: { strict: true }
    // },
    {
        path: "/monety/zweryfikuj/:slug+-:weight(1_50oz|1_4oz|1_2oz|1_20oz|1_25oz|5oz|1oz|10oz|1_10oz|2oz)-:metal(zloto|srebro)/",
        name: "verify",
        component: VerifyProduct,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/monety/:slug+-:weight(1_50oz|1_4oz|1_2oz|1_20oz|1_25oz|5oz|1oz|10oz|1_10oz|2oz)-:metal(zloto|srebro)/",
        name: "detail",
        component: Detail,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/",
        name: "home",
        component: HomePage,
    },
    {
        path: "/artykuly/",
        name: "articles",
        component: BlogArticleList,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/sitemap/",
        name: "sitemap",
        component: SiteMap,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: "/test/",
        name: "test",
        component: SoundTestPage,
        pathToRegexpOptions: { strict: true }
    },
    {
        path: '/404', component: NotFound
    },
    {
        path: "/:slug+/",
        name: "article",
        component: BlogArticle,
        pathToRegexpOptions: { strict: true }
    },
    { path: '*', redirect: '/404' },
]

var router = new VueRouter(
    {
        mode: 'history',
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    selector: to.hash,
                }
            }
            var position = { x: 0, y: 0 }
            if (savedPosition) {
                position = savedPosition
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(position)
                }, 250)
            })
        }


    })

export default router
