<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <blog-article-template :article="article"></blog-article-template>
    <social-share-buttons :url="urlContent" :title="this.article.title"></social-share-buttons>
  </div>
</template>

<script>
import articles from "../articles";
import Breadcrump from "./Breadcrump";
import BuyBoxWidget from "./BuyBoxWidget";
import SocialShareButtons from "./SocialShareButtons";
import BlogArticleTemplate from "./BlogArticleTemplate";

export default {
  name: "BlogArticle",
  data() {
    return {
      article: null,
      urlContent: ""
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.article.description },
        { name: "image", content: this.article.coverImageSrc },

        // Schema.org for Google
        { itemprop: "name", content: this.article.title },
        { itemprop: "description", content: this.article.description },
        { itemprop: "image", content: this.article.coverImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.article.title },
        { name: "twitter:description", content: this.article.description },
        { name: "twitter:image:src", content: this.article.coverImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.article.title },
        { property: "og:description", content: this.article.description },
        { property: "og:image", content: this.article.coverImageSrc },
        { property: "og:image:url", content: this.article.coverImageSrc },

        { property: "og:image:alt", content: this.article.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" }
      ],
      title: this.article.title,
      description: this.article.description
    };
  },
  beforeMount() {
    var currentSlug = this.$route.params.slug;
    var publishedArticles = articles.filter(art => art.isPublic);
    for (var idx in publishedArticles) {
      var article = publishedArticles[idx];
      if (article.slug == currentSlug) {
        this.article = article;
        break;
      }
    }
    const currentAbsoluteUrl = new URL(window.location.href);
    this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;
  },
  computed: {
    articleLoader() {
      return () => import(`@/articles/${this.$route.params.slug}.md`);
    },
    breadcrump() {
      return [
        {
          name: "Baza Wiedzy",
          to: {
            name: "articles"
          }
        },
        {
          name: this.article.title,
          to: {
            name: "article",
            params: {
              slug: this.article.slug
            }
          }
        }
      ];
    }
  },
  components: {
    Breadcrump,
    SocialShareButtons,
    BlogArticleTemplate
  }
};
</script>

<style>
.author-attr-link {
  color: inherit;
}
</style>
