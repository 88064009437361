<template>
  <div class="content">
    <h1>Mapa strony</h1>

    <h2>Baza Wiedzy o Monetach Bulionowych</h2>

    <ul>
      <li v-for="article in articles" :key="article.slug">
        <router-link
          class="link"
          :to="{name: 'article', params: {slug: article.slug }}"
        >{{ article.title }}</router-link>
      </li>
    </ul>

    <h2>Lista Monet Bulionowych w bazie ZbierajMonety.pl</h2>

    <ul>
      <li v-for="product in products" :key="product.slug + product.weight + product.metal">
        <router-link
          :to="{name: 'detail', params: {slug: product.slug, weight: product.weight, metal: product.metal == 'gold' ? 'zloto' : 'srebro'}}"
          class="link"
        >{{product.metal == 'gold' ? 'Złoty' : 'Srebrny' }} {{ product.name }} {{ product.weight }}</router-link>
      </li>
    </ul>

    <h2>Jak sprawdzić czy moneta jest prawdziwa?</h2>

    <ul>
      <li v-for="product in products" :key="product.slug">
        <router-link
          :to="{name: 'verify', params: {slug: product.slug, weight: product.weight, metal: product.metal == 'gold' ? 'zloto' : 'srebro'}}"
          class="link"
        >Zweryfikuj {{product.metal == 'gold' ? 'Złoty' : 'Srebrny' }} {{ product.name }}</router-link>
      </li>
    </ul>

    <h2>Porównania różnych monet bulionowych</h2>

    <div v-for="product1 in products" :key="product1.slug + product1.weight + product1.metal">
      <h3>{{product1.metal == 'gold' ? 'Złoty' : 'Srebrny' }} {{ product1.name }}</h3>
      <ul>
        <li
          v-for="product2 in productsWoSlug(product1.slug)"
          :key="'compare' + product1.slug + product1.weight + product2.slug + product2.weight"
        >
          <router-link
            class="link"
            :to="{ name: 'compare', params: {slug1: product1.slug, weight1: product1.weight, metal1: product1.metal == 'gold' ? 'zloto' : 'srebro', slug2: product2.slug, weight2: product2.weight, metal2: product2.metal == 'gold' ? 'zloto' : 'srebro'}}"
          >{{product1.metal == 'gold' ? 'Złoty' : 'Srebrny' }} {{ product1.name }} {{ product1.weight }} czy {{product2.metal == 'gold' ? 'Złoty' : 'Srebrny' }} {{ product2.name }} {{ product2.weight }}?</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import articles from "../articles.json";
import products from "../database.json";

export default {
  name: "SiteMap",
  data() {
    return {
      title: "Mapa Strony",
      description: "Zobacz wszystkie podstrony portalu.",
      articles: articles.filter((art) => art.isPublic),
      products,
    };
  },
  metaInfo() {
    return {
      // meta: [
      //   // general
      //   { name: "description", content: this.description },
      //   { name: "image", content: this.imageSrc },

      //   // Schema.org for Google
      //   { itemprop: "name", content: this.title },
      //   { itemprop: "description", content: this.description },
      //   { itemprop: "image", content: this.imageSrc },

      //   // twitter
      //   { name: "twitter:card", content: "summary_large_image" },
      //   { name: "twitter:title", content: this.title },
      //   { name: "twitter:description", content: this.description },
      //   { name: "twitter:image:src", content: this.imageSrc },

      //   // Open Graph general (Facebook, Pinterest & Google+)
      //   { property: "og:title", content: this.title },
      //   { property: "og:description", content: this.description },
      //   { property: "og:image", content: this.imageSrc },
      //   { property: "og:image:url", content: this.imageSrc },
      //   { property: "og:image:width", content: 650 * 2 },
      //   { property: "og:image:height", content: 341 * 2 },

      //   { property: "og:image:alt", content: this.title },
      //   { property: "og:url", content: this.urlContent },
      //   { property: "og:locale", content: "pl_PL" },
      //   { property: "og:type", content: "link" }

      //   // Open Graph - Product (e-commerce)
      //   // { property: 'product:price:currency', content: 'PLN'},
      //   // { property: 'product:price:amount', content: '6337.16'},
      //   // { property: 'product:brand', content: 'United States Mint'},
      // ],
      title: this.title,
      description: this.description,
    };
  },
  methods: {
    productsWoSlug(excludeSlug) {
      return this.products.filter((item) => item.slug != excludeSlug);
    },
  },
};
</script>
