<template>
  <table class="table is-striped is-narrow is-fullwidth is-hoverable">
    <thead>
      <tr>
        <th></th>
        <th>
          <template v-if="computedProduct2 != null">
            <router-link
              :to="{name: 'detail', params: {slug: computedProduct1.slug, weight: computedProduct1.weight.replace('/', '_'), metal: computedProduct1.metal == 'gold' ? 'zloto' : 'srebro'}}"
            >{{ computedProduct1.name }}</router-link>
          </template>
          <template v-else>
            <span>{{ computedProduct1.name }}</span>
          </template>
        </th>
        <th v-if="computedProduct2">
          <router-link
            :to="{name: 'detail', params: {slug: computedProduct2.slug, weight: computedProduct2.weight.replace('/', '_'), metal: computedProduct2.metal == 'gold' ? 'zloto' : 'srebro'}}"
          >{{ computedProduct2.name }}</router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <th>Budowa</th>
      <th></th>
      <th v-if="computedProduct2"></th>

      <tr>
        <td class="has-text-right">Metal</td>
        <td>
          <span class="tag is-warning" v-if="computedProduct1.metal == 'gold'">ZŁOTO</span>
          <span class="tag is-light" v-if="computedProduct1.metal == 'silver'">SREBRO</span>
        </td>
        <td v-if="computedProduct2">
          <span class="tag is-warning" v-if="computedProduct2.metal == 'gold'">ZŁOTO</span>
          <span class="tag is-light" v-if="computedProduct2.metal == 'silver'">SREBRO</span>
        </td>
      </tr>

      <tr>
        <td class="has-text-right">Próba</td>
        <td>{{ computedProduct1.purity }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.purity }}</td>
      </tr>

      <tr>
        <td class="has-text-right">Stop</td>
        <td>
          <ul v-if="computedProduct1.alloy">
            <li
              v-for="alloy in computedProduct1.alloy"
              :key="alloy.metal + alloy.amount"
            >{{ alloy.amount }}% {{ metalENG2PL[alloy.metal] }}</li>
          </ul>
          <span v-else>Czyste {{ metalENG2PL[computedProduct1.metal] }}</span>
        </td>
        <td v-if="computedProduct2">
          <ul v-if="computedProduct2.alloy">
            <li
              v-for="alloy  in computedProduct2.alloy"
              :key="alloy.metal + alloy.amount"
            >{{ alloy.amount }}% {{ metalENG2PL[alloy.metal] }}</li>
          </ul>
          <span v-else>Czyste {{ metalENG2PL[computedProduct2.metal] }}</span>
        </td>
      </tr>

      <tr>
        <td class="has-text-right">Waga</td>
        <td>{{ computedProduct1.weight }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.weight }}</td>
      </tr>

      <tr>
        <td class="has-text-right">Waga (g)</td>
        <td>{{ computedProduct1.weight_g }}g</td>
        <td v-if="computedProduct2">{{ computedProduct2.weight_g }}g</td>
      </tr>

      <tr>
        <td class="has-text-right">Waga kruszcu (g)</td>
        <td>{{ computedProduct1.weight_metal_g }}g</td>
        <td v-if="computedProduct2">{{ computedProduct2.weight_metal_g }}g</td>
      </tr>

      <tr>
        <td class="has-text-right">Średnica</td>
        <td>{{ computedProduct1.diameter }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.diameter }}</td>
      </tr>

      <tr>
        <td class="has-text-right">Grubość</td>
        <td>{{ computedProduct1.thickness }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.thickness }}</td>
      </tr>
    </tbody>
    <tbody>
      <th>Pochodzenie</th>
      <th></th>
      <th v-if="computedProduct2"></th>

      <tr>
        <td class="has-text-right">Producent</td>
        <td>
          {{ computedProduct1.producer.name }}
          <br />
          <figure class="image is-64x64">
            <img :src="computedProduct1.producer.logo_src" />
          </figure>
        </td>
        <td v-if="computedProduct2">
          {{ computedProduct2.producer.name }}
          <br />
          <figure class="image is-64x64">
            <img :src="computedProduct2.producer.logo_src" />
          </figure>
        </td>
      </tr>

      <tr>
        <td class="has-text-right">Kraj</td>
        <td>{{ computedProduct1.country }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.country }}</td>
      </tr>
    </tbody>
    <tbody>
      <th>Szczegóły</th>
      <th></th>
      <th v-if="computedProduct2"></th>

      <tr>
        <td class="has-text-right">Oryginalna nazwa</td>
        <td>{{ computedProduct1.name_org }}</td>
        <td v-if="computedProduct2">{{ computedProduct2.name_org }}</td>
      </tr>

      <tr>
        <td class="has-text-right">Nominał</td>
        <td>
          <p v-if="computedProduct1.denomination">{{ computedProduct1.denomination }}</p>
          <p v-else>n/d</p>
        </td>
        <td v-if="computedProduct2">
          <p v-if="computedProduct2.denomination">{{ computedProduct2.denomination }}</p>
          <p v-else>n/d</p>
        </td>
      </tr>

      <tr>
        <td class="has-text-right">Autor zdjęcia</td>
        <td>
          <a
            :href="computedProduct1.image_license.source"
            class="is-link"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >{{ computedProduct1.image_license.author }}</a>
          ,
          <a
            :href="computedProduct1.image_license.link"
            class="is-link"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >{{ computedProduct1.image_license.name }}</a>
        </td>
        <td v-if="computedProduct2">
          <a
            :href="computedProduct2.image_license.source"
            class="is-link"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >{{ computedProduct2.image_license.author }}</a>
          ,
          <a
            :href="computedProduct2.image_license.link"
            class="is-link"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >{{ computedProduct2.image_license.name }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "SideBySideCompareTable",
  props: ["product1", "variant1", "product2", "variant2"],
  computed: {
    computedProduct1() {
      return this.mergeProductWithVariant(this.product1, this.variant1);
    },
    computedProduct2() {
      return this.mergeProductWithVariant(this.product2, this.variant2);
    }
  }
};
</script>
