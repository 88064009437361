<template>
  <div>
    <div class="section">
      <div class="columns is-desktop">
        <div class="column" v-if="filterkeyword == ''">
          <div class="field has-addons">
            <div class="control">
              <input class="input" type="text" placeholder="np. Bizon" v-model.trim="searchInput" />
            </div>
            <div class="control">
              <a class="button is-primary">Szukaj</a>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="dropdown" :class="{'is-active': isSortDropdownActive}">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="clickedDropdownButton"
              >
                <span>
                  Sortowanie
                  <span v-if="sortBy == 'price_asc'">
                    <span>(Cena</span>
                    <span class="icon is-small">
                      <i class="fas fa-signal" aria-hidden="true"></i>
                    </span>
                    <span>)</span>
                  </span>

                  <span v-if="sortBy == 'price_dsc'">
                    <span>(Cena</span>
                    <span class="icon is-small">
                      <i class="fas fa-signal" aria-hidden="true" style="transform: scaleX(-1);"></i>
                    </span>
                    <span>)</span>
                  </span>

                  <span v-if="sortBy == 'name_asc'">(Nazwa A-Z)</span>

                  <span v-if="sortBy == 'name_dsc'">(Nazwa Z-A)</span>
                </span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>

            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                  class="dropdown-item"
                  @click="setSortByAndCloseDropDown('price_asc')"
                  :class="{'is-active': sortBy == 'price_asc'}"
                >
                  <span>Cena</span>
                  <span class="icon is-small">
                    <i class="fas fa-signal" aria-hidden="true"></i>
                  </span>
                </a>

                <a
                  class="dropdown-item"
                  @click="setSortByAndCloseDropDown('price_dsc')"
                  :class="{'is-active': sortBy == 'price_dsc'}"
                >
                  <span>Cena</span>
                  <span class="icon is-small">
                    <i class="fas fa-signal" aria-hidden="true" style="transform: scaleX(-1);"></i>
                  </span>
                </a>

                <hr class="dropdown-divider" />

                <a
                  class="dropdown-item"
                  @click="setSortByAndCloseDropDown('name_asc')"
                  :class="{'is-active': sortBy == 'name_asc'}"
                >
                  <span>Nazwa A-Z</span>
                </a>

                <a
                  class="dropdown-item"
                  @click="setSortByAndCloseDropDown('name_dsc')"
                  :class="{'is-active': sortBy == 'name_dsc'}"
                >
                  <span>Nazwa Z-A</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <label class="checkbox">
            <input type="checkbox" v-model="isOnlyPrivateOffers" />
            Tylko prywatne oferty
          </label>
        </div>
      </div>
    </div>

    <span class="content">
      <p>Ofert: {{ sortedAndFilteredProducts.length }}</p>
    </span>
    <article class="media" v-for="product in sortedAndFilteredProducts" :key="product.offer_id">
      <figure class="media-left">
        <p class="image is-128x128">
          <img :src="getThumbnail(product)" :alt="'Zdjęcie przodu monety ' + product.name" />
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <span>{{ product.name }} {{ product.weight }}</span>
            <br />
            <small
              v-if="product.private_offer"
              class="has-text-grey"
            >Od: @{{ product.retailer.name }}</small>
            <small v-else class="has-text-grey">Od: {{ product.retailer.name }}</small>
            <br />
            <strong class="is-size-5">{{ formatPrice(product.price) }}</strong>
            <br />
            <!-- <small>{{ formatRetailerMargin(calculateRetailerMargin(product.price)) }}</small> -->
          </p>
        </div>

        <router-link
          v-if="product.private_offer"
          :to="{name: 'offer', params: {offerid: product.offer_id, slug: product.slug}}"
          class="button is-primary"
        >
          <span class="icon">
            <i class="fas fa-shopping-cart" aria-hidden="true"></i>
          </span>
          <span>Zobacz ofertę</span>
        </router-link>

        <a
          v-else
          :href="product.product_link"
          rel="nofollow noopener noreferrer"
          target="_blank"
          class="button is-link"
        >
          <span>Idź do sklepu</span>
          <span class="icon">
            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
          </span>
        </a>
      </div>
    </article>
  </div>
</template>

<script>
import json from "../offers.json";

export default {
  props: {
    filterkeyword: {
      type: String,
      default: ""
    },
    filtermetal: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      goldPriceUsdPerUnce: 1500,
      UsdToPlnRate: 3.98,
      products: json,
      isOnlyPrivateOffers: false,
      isSortDropdownActive: false,
      sortBy: "price_asc",
      searchInput: "",
      moneyFormatter: new Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: "PLN",
        minimumFractionDigits: 2
      }),
      percentFormatter: new Intl.NumberFormat("pl-PL", {
        style: "percent",
        minimumFractionDigits: 2
      })
    };
  },
  methods: {
    getThumbnail(product) {
      if (product.images.length != 0) {
        return `/images/${product.offer_id}/${product.images[0]}`;
      }
    },
    setSortByAndCloseDropDown(by) {
      this.isSortDropdownActive = false;
      this.sortBy = by;
    },
    clickedDropdownButton() {
      this.isSortDropdownActive = !this.isSortDropdownActive;
    },
    calculateRetailerMargin(price) {
      // calculates how much the product is more expensive than the market price
      var marketPrice = this.goldPriceUsdPerUnce * this.UsdToPlnRate;
      return price / marketPrice - 1;
    },
    formatPrice(price) {
      return this.moneyFormatter.format(price);
    },
    formatPercent(number) {
      return this.percentFormatter.format(number);
    },
    formatRetailerMargin(number) {
      var localeFormatedPrice = this.formatPercent(number);
      if (number >= 0) return "+" + localeFormatedPrice;

      return localeFormatedPrice;
    }
  },
  computed: {
    sortedAndFilteredProducts() {
      var toReturn = this.products;

      var self = this;
      if (this.searchInput != "") {
        toReturn = toReturn.filter(item =>
          item.name.toLowerCase().includes(self.searchInput.toLowerCase())
        );
      }

      if (this.isOnlyPrivateOffers) {
        toReturn = toReturn.filter(item => item.private_offer == true);
      }

      if (this.filterkeyword != "") {
        toReturn = toReturn.filter(item =>
          item.name.toLowerCase().includes(self.filterkeyword.toLowerCase())
        );
      }

      if (this.filtermetal !== null) {
        toReturn = toReturn.filter(
          item => item.details.metal == this.filtermetal
        );
      }

      if (this.sortBy == "price_asc") {
        toReturn = toReturn.sort(
          (product1, product2) =>
            Number(product1.price) > Number(product2.price)
        );
      }
      if (this.sortBy == "price_dsc") {
        toReturn = toReturn.sort(
          (product1, product2) =>
            Number(product1.price) < Number(product2.price)
        );
      }
      if (this.sortBy == "name_asc") {
        toReturn = toReturn.sort(
          (product1, product2) => product1.name > product2.name
        );
      }

      if (this.sortBy == "name_dsc") {
        toReturn = toReturn.sort(
          (product1, product2) => product1.name < product2.name
        );
      }
      return toReturn;
    }
  }
};
</script>
