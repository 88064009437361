<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>

    <div id="socialWindow">
      <h1
        class="title content is-size-5 has-text-centered"
      >{{ computedProduct1.name }} vs {{ computedProduct2.name }}</h1>

      <div class="columns is-gapless is-mobile">
        <div class="column">
          <div class="columns is-vcentered is-mobile is-gapless">
            <div class="column is-3-tablet">
              <specification-panel :product="computedProduct1"></specification-panel>
            </div>
            <div class="column">
              <figure class="image is-square">
                <picture>
                  <source type="image/webp" :srcset="computedProduct1.image_src_webp" />
                  <img :src="computedProduct1.image_src_png" />
                </picture>
              </figure>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="columns is-vcentered is-mobile is-gapless">
            <div class="column">
              <figure class="image is-square">
                <picture>
                  <source type="image/webp" :srcset="computedProduct2.image_src_webp" />
                  <img :src="computedProduct2.image_src_png" />
                </picture>
              </figure>
            </div>
            <div class="column is-3-tablet">
              <specification-panel :product="computedProduct2"></specification-panel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="subtitle">Porównanie specyfikacji</h2>

    <side-by-side-compare-table :product1="computedProduct1" :product2="computedProduct2"></side-by-side-compare-table>
    <social-share-buttons :url="urlContent" :title="title"></social-share-buttons>

    <div class="content">
      <p>
        <template v-if="cp1.name != cp2.name">
          W tym porównaniu zestawimy ze sobą dwie
          <template v-if="cp1.metal == cp2.metal">
            <template v-if="cp1.metal == 'gold'">złote</template>
            <template v-if="cp1.metal == 'silver'">srebrne</template>
            monety
          </template>
          <template v-else>
            <template v-if="cp1.metal=='gold'">monety, pierwsza jest złota a druga srebrna</template>
            <template v-if="cp1.metal=='silver'">monety, pierwsza jest srebrna a druga złota</template>
          </template>

          <template
            v-if="cp1.weight == cp2.weight"
          >&nbsp;o wadze {{ cp1.weight }}. Czyli {{ cp1.name }} oraz {{ cp2.name }}.&nbsp;</template>
          <template
            v-else
          >: {{ cp1.name }} o wadze {{ cp1.weight }} oraz {{ cp2.name }} o wadze {{ cp2.weight }}.&nbsp;</template>

          <template
            v-if="cp1.producer.name == cp2.producer.name"
          >Obie zostały wybite przez {{ cp1.producer.name }}.</template>
          <template
            v-else
          >Moneta {{ cp1.name_org }} została wybita przez {{ cp1.producer.name }}, a {{ cp2.name_org }} przez {{ cp2.producer.name }}.&nbsp;</template>

          <template v-if="cp1.metal == cp2.metal">
            Obie monety są
            <template v-if="cp1.metal == 'gold'">
              złote.
              <template
                v-if="cp1.alloy && !cp2.alloy"
              >Jednak {{ cp1.name }} posiada domieszki innych metali: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}. Podczas gdy {{ cp2.name }} jest z czystego złota.</template>
              <template
                v-if="!cp1.alloy && cp2.alloy"
              >Jednak {{ cp2.name }} posiada domieszki innych metali: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}, a {{ cp1.name }} jest z czystego złota.</template>
              <template
                v-if="cp1.alloy && cp2.alloy"
              >Nie są jednak z czystego złota. {{cp1.name}} posiada domieszki: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}, a {{cp2.name}} posiada domieszki: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}</template>
            </template>
            <template v-if="cp1.metal == 'silver'">
              srebrne.
              <template
                v-if="cp1.alloy && !cp2.alloy"
              >Jednak {{ cp1.name }} posiada domieszki innych metali: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}. Podczas gdy {{ cp2.name }} jest z czystego srebra.</template>
              <template
                v-if="!cp1.alloy && cp2.alloy"
              >Jednak {{ cp2.name }} posiada domieszki innych metali: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}, a {{ cp1.name }} jest z czystego złota.</template>
              <template
                v-if="cp1.alloy && cp2.alloy"
              >Nie są jednak z czystego srebra. {{cp1.name}} posiada domieszki: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}, a {{cp2.name}} posiada domieszki: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}</template>
            </template>
          </template>
          <template v-else>
            {{ cp1.name }} jest ze
            <template v-if="cp1.metal == 'gold'">
              złota,
              <template
                v-if="cp1.alloy"
              >ale posiada też domieszki innych metali: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}</template>
            </template>
            <template v-if="cp1.metal == 'silver'">
              srebra,
              <template
                v-if="cp1.alloy"
              >ale posiada też domieszki innych metali: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}</template>
            </template>
            a {{ cp2.name }} ze
            <template v-if="cp2.metal == 'gold'">złota</template>
            <template v-if="cp2.metal == 'silver'">srebra</template>
            <template
              v-if="cp2.alloy"
            >&nbsp;i posiada domieszki: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}.</template>
          </template>
        </template>
        <template v-else>
          <template
            v-if="cp1.name == cp2.name && cp1.weight == cp2.weight && cp1.metal == cp2.metal"
          >A ty śmieszku ( ͡° ͜ʖ ͡°) {{ cp1.name }} o wadze {{ cp1.weight }} porównać do samej siebie? Wybierz proszę inną parę ;)</template>
          <template v-else>
            W tym porównaniu zestawimy ze sobą dwie różne wersje monety {{cp1.name}}.
            <template
              v-if="cp1.weight == cp2.weight"
            >
              Obie wersje ważą {{ cp1.weight }}, więc jedyne co je różni to metal z jakiego są wykonane. Wersja po lewej stronie wykonana jest z
              <template
                v-if="cp1.metal == 'silver'"
              >
                <template v-if="!cp1.alloy">czystego</template>srebra, a po prawej ze
                <template v-if="!cp2.alloy">czystego</template> złota.
              </template>
              <template v-if="cp1.metal == 'gold'">
                <template v-if="!cp1.alloy">czystego</template> złota, a po prawej ze
                <template v-if="!cp2.alloy">czystego</template> srebra.
              </template>
              <template
                v-if="cp1.alloy"
              >&nbsp;Wersja lewa posiada domieszki innych metali: {{ cp1.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}.</template>
              <template
                v-if="cp2.alloy"
              >&nbsp;Wersja prawa ma domieszki: {{ cp2.alloy.slice(1).map(al => metalENG2PL[al.metal] + ' ' + al.amount + '%').join(', ') }}.</template>
            </template>
            <template v-else>Wersja po lewej waży {{ cp1.weight }}, a po prawej {{cp2.weight}}.</template>
          </template>
        </template>
      </p>
    </div>
    <p>
      <template v-if="cp1.metal == cp2.metal"></template>
      <template v-else></template>
    </p>

    <template v-if="isOffersOn">
      <h2 class="subtitle">Kup {{ computedProduct1.name }}</h2>

      <pricing-table :filterkeyword="computedProduct1.name" :filtermetal="computedProduct1.metal"></pricing-table>

      <h2 class="subtitle">Kup {{ computedProduct2.name }}</h2>

      <pricing-table :filterkeyword="computedProduct2.name" :filtermetal="computedProduct2.metal"></pricing-table>
    </template>
  </div>
</template>

<script>
import json from "../database.json";

import SpecificationPanel from "./SpecificationPanel";
import SideBySideCompareTable from "./SideBySideCompareTable";
import PricingTable from "./PricingTable";
import Breadcrump from "./Breadcrump";
import SocialShareButtons from "./SocialShareButtons";

export default {
  data() {
    return {
      product1: null,
      product2: null,
      database: null,
      title: "ZbierajMonety.pl",
      description: "",
      imageSrc: "",
      urlContent: "",
      currentVariant1: "1oz",
      currentVariant2: "1oz",
    };
  },
  watch: {
    product2() {
      if (this.product1 != null && this.product2 != null) {
        const metalDescription1 =
          this.product1.metal == "gold" ? "Złoty" : "Srebrny";
        const metalDescription2 =
          this.product2.metal == "gold" ? "Złoty" : "Srebrny";
        this.title = `${metalDescription1} ${
          this.product1.name
        } ${this.addSpaceToWeight(
          this.currentVariant1
        )} czy ${metalDescription2} ${
          this.product2.name
        } ${this.addSpaceToWeight(this.currentVariant2)}?`;
        this.product2.metal == "gold" ? "Złoty" : "Srebrny";
        this.description = `Sprawdź którą monetę lepiej kupić. Szczegółowe porównanie monet ${metalDescription1} ${this.product1.name} ${this.currentVariant1} oraz ${metalDescription2} ${this.product2.name} ${this.currentVariant2}.`;

        const currentAbsoluteUrl = new URL(window.location.href);
        const currentAbsoluteUrlSplitted = currentAbsoluteUrl.pathname.split(
          "/"
        );
        const endSlug =
          currentAbsoluteUrlSplitted[currentAbsoluteUrlSplitted.length - 2];
        this.imageSrc = `https://zbierajmonety.pl/img${this.$route.path}${endSlug}.png`;

        this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;
      }
    },
    // $route() {
    //   this.product1 = this.database[this.$route.params.product1];
    //   this.product2 = this.database[this.$route.params.product2];
    // }
  },
  computed: {
    computedProduct1() {
      return this.mergeProductWithVariant(this.product1, this.currentVariant1);
    },
    computedProduct2() {
      return this.mergeProductWithVariant(this.product2, this.currentVariant2);
    },
    cp1() {
      return this.computedProduct1;
    },
    cp2() {
      return this.computedProduct2;
    },
    breadcrump() {
      return [
        {
          name: "Porównanie",
          to: {
            name: "comparechoice",
          },
        },
        {
          name: `${this.product1.name} vs ${this.product2.name}`,
          to: {
            name: "compare",
            params: {
              product1: this.product1.slug,
              product2: this.product2.slug,
            },
          },
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image", content: this.imageSrc },
        { property: "og:image:width", content: 650 * 2 },
        { property: "og:image:height", content: 341 * 2 },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.imageSrc },
        { property: "og:image:url", content: this.imageSrc },
        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "website" },

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ],
    };
  },
  beforeMount() {
    // prepare database:

    this.database = {};
    for (var i in json) {
      var elem = json[i];
      this.database[`${elem.slug}-${elem.metal}`] = elem;
    }
    let metal1 = this.$route.params.metal1 == "zloto" ? "gold" : "silver";
    this.product1 = this.database[`${this.$route.params.slug1}-${metal1}`];
    this.currentVariant1 = this.$route.params.weight1.replace("_", "/");

    let metal2 = this.$route.params.metal2 == "zloto" ? "gold" : "silver";
    this.product2 = this.database[`${this.$route.params.slug2}-${metal2}`];
    this.currentVariant2 = this.$route.params.weight2.replace("_", "/");
  },
  components: {
    SpecificationPanel,
    SideBySideCompareTable,
    PricingTable,
    Breadcrump,
    SocialShareButtons,
  },
};
</script>

<style scoped>
.subtitle {
  margin-top: 2rem;
}
</style>
