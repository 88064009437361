<template>
  <article class="content">
    <component :is="articleLoader" :key="article.slug" :isMinimal="isMinimal">
      <template #title>
        <router-link tag="a" class="is-link" :to="{name: 'article', params: {slug: article.slug}}">
          <h2 v-if="isMinimal" class="title">{{ article.title}}</h2>
          <h1 v-else class="title">{{ article.title}}</h1>
        </router-link>
      </template>
      <template #publishDate>
        <p class="subtitle">{{ article.publishDate}}</p>
      </template>
      <template #coverImage>
        <template v-if="isMinimal">
          <router-link
            tag="a"
            class="is-link"
            :to="{name: 'article', params: {slug: article.slug}}"
          >
            <img :src="article.coverImageSrc" :alt="article.coverImageAlt" />
          </router-link>
        </template>
        <template v-else>
          <img :src="article.coverImageSrc" :alt="article.coverImageAlt" />
        </template>
      </template>
      <template #coverImageAttr>
        <small class="is-size-7 has-text-grey-light is-pulled-right">
          Image by
          <a
            class="is-link author-attr-link"
            rel="nofollow noopener noreferrer"
            target="_blank"
            :href="article.coverImageAuthorLink"
          >{{ article.coverImageAuthor }}</a>
        </small>
      </template>
      <template #bbWidget="slotProps">
        <buy-box-widget :bbId="slotProps.bbId" :bookNumber="slotProps.bookNumber"></buy-box-widget>
      </template>
      <template v-if="isMinimal" #content>
        <router-link tag="a" class="is-link" :to="{name: 'article', params: {slug: article.slug}}">
          <strong>{{ article.teaserLink }}→</strong>
        </router-link>
      </template>
    </component>
    <hr />
  </article>
</template>

<script>
import BuyBoxWidget from "./BuyBoxWidget";

export default {
  name: "BlogArticle",
  data() {
    return {
      urlContent: ""
    };
  },
  props: {
    article: { type: Object },
    isMinimal: { type: Boolean, default: false }
  },
  computed: {
    articleLoader() {
      return () => import(`@/articles/${this.article.slug}.md`);
    }
  },
  components: {
    BuyBoxWidget
  }
};
</script>

<style>
.author-attr-link {
  color: inherit;
}
</style>
