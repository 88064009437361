<template>
<nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
  <ul>
    <li>
      <router-link :to="{name: 'home'}" class="link">
        Główna
      </router-link>
    </li>
    <li
      v-for="(routeElem, index) in breadcrump"
      :key="routeElem.name"
      :class="{'is-active': index == breadcrump.length - 1}"
    >
      <router-link
        class="link"
        :to="routeElem.to"
      >
        {{ routeElem.name }}
      </router-link>

    </li>
  </ul>
</nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: [
    "breadcrump"
  ]
}
</script>
