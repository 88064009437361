<template>
  <div>
    <div class="columns is-mobile has-text-centered is-centered">
      <div class="column">
        <figure class="image is-80x80 has-image-centered">
          <picture>
            <source type="image/webp" :srcset="product1.image_src_webp" />
            <img :src="product1.image_src_png" :alt="'Miniaturka monety' + product1.name" />
          </picture>
        </figure>
        <span>{{product1.name}} {{product1.weight}}</span>
      </div>

      <div class="column">
        <router-link
          class="button is-primary"
          :to="{ name: 'compare', params: { slug1: product1.slug, weight1: product1.weight.replace('/', '_'), metal1: product1.metal == 'gold' ? 'zloto' : 'srebro', slug2: product2.slug, weight2: '1oz', metal2: product2.metal == 'gold' ? 'zloto' : 'srebro'}}"
        >
          <span class="icon is-small">
            <i class="fas fa-eye"></i>
          </span>
          <span>Porównaj</span>
        </router-link>
      </div>

      <div class="column">
        <figure class="image is-80x80 has-image-centered">
          <picture>
            <source type="image/webp" :srcset="product2.image_src_webp" />
            <img :src="product2.image_src_png" :alt="'Miniaturka monety' + product2.name" />
          </picture>
        </figure>
        <span>{{product2.name}} {{product2.weight}}</span>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "CompareWidget",
  props: ["product1", "product2"],
};
</script>
