<template>
  <div class="columns is-multiline">
    <div class="column has-text-centered">
      <div>
        <p class="heading">Nominał</p>
        <p v-if="computedProduct.denomination">{{ computedProduct.denomination }}</p>
        <p v-else>n/d</p>
      </div>
    </div>

    <div class="column has-text-centered">
      <div>
        <p class="heading">Waga</p>
        <p>{{ computedProduct.weight }}</p>
      </div>
    </div>
    <div class="column has-text-centered">
      <div>
        <p class="heading">Próba</p>
        <p>{{ computedProduct.purity }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecificationPanel",
  props: ["product", "variant"],
  computed: {
    computedProduct() {
      return this.mergeProductWithVariant(this.product, this.variant);
    }
  }
};
</script>
