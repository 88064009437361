<template>
  <div class="card">
    <div class="card-image">
      <figure class="image">
        <router-link class="link" :to="{name: 'article', params: {slug: article.slug }}">
          <img :src="article.coverImageSrc" :alt="article.title" />
        </router-link>
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <router-link class="link" :to="{name: 'article', params: {slug: article.slug }}">
          <div class="media-content">
            <p class="title is-4">{{ article.title }}</p>
            <p class="subtitle is-6">{{ article.publishDate }}</p>
          </div>
        </router-link>
      </div>

      <div class="content">
        {{ article.teaser }}
        <router-link
          class="link"
          :to="{name: 'article', params: {slug: article.slug }}"
        >{{ article.teaserLink }}→</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleWidget",
  props: ["article"],
};
</script>
