<template>
  <nav class="navbar is-fixed-top navbar-faded" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img
          src="@/assets/images/logo-zbieraj-monety-male.png"
          alt="Logo portalu ZbierajMonety.pl - inwestuj w monety lokacyjne"
        />
      </a>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        :class="{'is-active': isActive}"
        @click="isActive = !isActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" :class="{'is-active': isActive}">
      <div class="navbar-start">
        <router-link class="navbar-item" :to="{ name: 'home' }">
          <span>Główna</span>
        </router-link>

        <router-link class="navbar-item" :to="{ name: 'articles'}">
          <span>Baza Wiedzy</span>
        </router-link>

        <router-link class="navbar-item" :to="{ name: 'productlist'}">
          <span>Lista Monet</span>
        </router-link>
        <!--
        <router-link v-if="isOffersOn" class="navbar-item" :to="{ name: 'offerlist'}">
          <span>Kup Monety</span>
        </router-link>-->

        <router-link class="navbar-item" :to="{ name: 'comparechoice'}">
          <span>Porównaj Monety</span>
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'test'}">
          <span>Test Dźwiękowy</span>
        </router-link>
      </div>

      <div class="navbar-end" v-if="isActive">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary is-outlined" @click="isActive = false">
              <span class="icon is-small">
                <i class="fas fa-times"></i>
              </span>
              <span>Zamknij menu</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isActive: false,
      searchPlaceholders: [
        "np. Australijski Kangur",
        "np. Orzeł Bielik 1oz",
        "np. Wiedeńscy Filharmonicy",
        "np. 1/2 oz Krugerrand",
        "np. Maple Leaf",
      ],
    };
  },
  watch: {
    $route() {
      this.isActive = false;
    },
  },
  methods: {
    getRandomPlaceHolder() {
      return this.searchPlaceholders[
        Math.floor(Math.random() * this.searchPlaceholders.length)
      ];
    },
  },
};
</script>


<style>
.navbar-faded {
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

/*.navbar-item.brand-text {
  font-weight: 300;
}
.navbar-item, .navbar-link {
  font-size: 14px;
}
.router-link-exact-active {
  font-weight: 700;
}
*/
</style>
