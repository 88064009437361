<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>

    <div id="socialWindow">
      <h1 class="title is-size-3 has-text-centered">{{ product.name }}</h1>

      <div class="columns is-vcentered is-gapless is-mobile">
        <div class="column is-1-tablet">
          <specification-panel :product="product" :variant="currentVariant"></specification-panel>
        </div>

        <div class="column"></div>
        <div class="column">
          <figure class="image is-256x256">
            <picture>
              <source type="image/webp" :srcset="product.image_src_webp" />
              <img :src="product.image_src_png"
                :alt="'Zdjęcie ' + product.metal == 'gold' ? 'zloto' : 'srebro' + ' ' + product.name + 'o wadze ' + product.weight" />
            </picture>
          </figure>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <router-link
      :to="{ name: 'verify', params: { slug: product.slug, weight: currentVariant.replace('/', '_'), metal: product.metal == 'gold' ? 'zloto' : 'srebro' } }"
      tag="a" class="is-link button is-medium is-fullwidth is-outlined verify-button">
      <span class="icon">
        <i class="fas fa-tasks"></i>
      </span>
      <span>Zweryfikuj monetę</span>
    </router-link>
    <a :href="currentVariantPurchaseLink" v-show="currentVariantPurchaseLink" target="_blank"
      class="button is-link is-medium is-fullwidth is-outlined verify-button">
      <span class="icon">
        <i class="fas fa-dollar-sign"></i>
      </span>
      <span>Sprawdź aktualną cenę</span>
    </a>
    <h2 class="subtitle">Specyfikacja</h2>
    <div class="tabs is-boxed is-centered">
      <ul>
        <li v-for="variant in product.variants" :key="variant.weight"
          :class="{ 'is-active': currentVariant == variant.weight }" @click="currentVariant = variant.weight">
          <router-link tag="a" class="is-link"
            :to="{ name: 'detail', params: { slug: product.slug, weight: variant.weight.replace('/', '_'), metal: product.metal == 'gold' ? 'zloto' : 'srebro' } }">{{
              variant.weight }}</router-link>
        </li>
      </ul>
    </div>
    <side-by-side-compare-table :product1="product" :variant1="currentVariant"></side-by-side-compare-table>

    <template v-if="product.description">
      <template v-if="product.description.general">
        <h2 class="subtitle">Opis</h2>
        <p style="white-space: pre-line;">{{ product.description.general }}</p>
      </template>

      <template v-if="product.description.physical_featrures">
        <h2 class="subtitle">Cechy fizyczne</h2>
        <p>{{ product.description.physical_featrures }}</p>
      </template>

      <template v-if="product.description.falsification">
        <h2 class="subtitle">Fałszowanie</h2>
        <p>{{ product.description.falsification }}</p>
      </template>
    </template>
    <social-share-buttons :url="urlContent" :title="title"></social-share-buttons>

    <template v-if="isOffersOn">
      <h2 class="subtitle">Kup {{ product.metal == 'gold' ? 'złoty' : 'srebrny' }} {{ product.name }} o wadze
        {{ currentVariant }}</h2>
      <pricing-table :filterkeyword="product.name" :filtermetal="product.metal"></pricing-table>
    </template>

    <template v-if="product.articles">
      <h2 class="subtitle">Powiązane artykuły</h2>
      <div class="content">
        <ul>
          <li v-for="article in product.articles" :key="article.slug">
            <router-link :to="{ name: 'article', params: { slug: article.slug } }" tag="a" class="is-link">{{ article.text
            }}</router-link>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="otherMetalVersion">
      <h2 class="subtitle">Zobacz wersję {{ product.metal == 'gold' ? 'srebrną' : 'złotą' }}</h2>
      <product-widget :product="otherMetalVersion"></product-widget>
    </template>

    <h2 class="subtitle">Porównaj z innymi monetami</h2>
    <compare-widget v-for="cmpProduct in productsWoSlug(product.slug)" :key="cmpProduct.slug + cmpProduct.metal"
      :product1="mergeProductWithVariant(product, currentVariant)" :product2="cmpProduct"></compare-widget>
  </div>
</template>

<script>
import json from "../database.json";

import Breadcrump from "./Breadcrump";
import CompareWidget from "./CompareWidget";
import PricingTable from "./PricingTable";
import ProductWidget from "./ProductWidget";
import SideBySideCompareTable from "./SideBySideCompareTable";
import SocialShareButtons from "./SocialShareButtons";
import SpecificationPanel from "./SpecificationPanel";

export default {
  data() {
    return {
      currentVariant: "1oz",
      database: null,
      description: "",
      imageSrc: "",
      otherMetalVersion: null,
      producerName: "",
      productName: "",
      productNameOrg: "",
      productImage: "",
      productDescription: "",
      productMetal: "",
      currentVariantWeightG: "",
      currentVariantDiameter: "",
      currentVariantPurchaseLink: "",
      product: null,
      title: "ZbierajMonety.pl",
      urlContent: "",
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.imageSrc },
        { property: "og:image:url", content: this.imageSrc },
        { property: "og:image:width", content: 650 * 2 },
        { property: "og:image:height", content: 341 * 2 },

        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ],
      title: this.title,
      description: this.description,
    };
  },
  jsonld() {
    return {
      "@context": "http://schema.org/",
      "@type": "Product",
      brand: this.producerName,
      image: this.productImage,
      name: this.productName,
      alternateName: this.productNameOrg,
      description: this.productDescription,
      material: this.productMetal == "gold" ? "Złoto" : "Srebro",
      weight: {
        "@type": "QuantitativeValue",
        value: this.currentVariantWeightG + " g",
      },
      height: this.currentVariantDiameter,
      width: this.currentVariantDiameter,
      aggregateRating: {
        "@type": "aggregateRating",
        ratingValue: 4.9,
        reviewCount: 21,
        bestRating: 5,
        worstRating: 0.0,
      },
    };
  },
  beforeMount() {
    // prepare database:

    this.database = {};
    for (let i in json) {
      let elem = json[i];
      this.database[`${elem.slug}-${elem.metal}`] = elem;
    }
    let metal = this.$route.params.metal == "zloto" ? "gold" : "silver";
    this.product = this.database[`${this.$route.params.slug}-${metal}`];
    this.currentVariant = this.$route.params.weight.replace("_", "/");

    // if its gold, take silver, if silver, take gold, just to show other version in desc.
    let otherMetal = metal == "gold" ? "silver" : "gold";
    this.otherMetalVersion = this.database[
      `${this.$route.params.slug}-${otherMetal}`
    ];
  },
  watch: {
    // '$route' (to, from) {
    // $route() {
    //   this.product = this.database[this.$route.params.product];
    // },
    product() {
      if (this.product != null) {
        const metalDescription =
          this.product.metal == "gold" ? "Złoty" : "Srebrny";
        this.title = `${metalDescription} ${this.product.name
          } o wadze ${this.addSpaceToWeight(this.currentVariant)}`;
        this.description = `Szczegółowy opis parametrów monety ${metalDescription} ${this.product.name} o wadze ${this.currentVariant}, takich jak wymiary, próba czy dane producenta.`;
        const currentAbsoluteUrl = new URL(window.location.href);
        const currentAbsoluteUrlSplitted = currentAbsoluteUrl.pathname.split(
          "/"
        );
        const endSlug =
          currentAbsoluteUrlSplitted[currentAbsoluteUrlSplitted.length - 2];
        this.imageSrc = `https://zbierajmonety.pl/img${this.$route.path}${endSlug}.png`;

        // this.urlContent = window.location.href;
        this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;

        let mergedProduct = this.mergeProductWithVariant(
          this.product,
          this.currentVariant
        );
        this.productImage =
          "https://zbierajmonety.pl" + mergedProduct.image_src_png;
        this.producerName = mergedProduct.producer.name;
        this.productName = mergedProduct.name + " " + mergedProduct.weight;
        this.productNameOrg = mergedProduct.name_org;
        this.productDescription = mergedProduct.description.general;
        this.productMetal = mergedProduct.metal;
        this.currentVariantWeightG = mergedProduct.weight_g;
        this.currentVariantDiameter = mergedProduct.diameter;
        this.currentVariantPurchaseLink = mergedProduct.purchaseLink;
      }
    },
  },
  methods: {
    productsWoSlug(excludeSlug) {
      return json.filter((item) => item.slug != excludeSlug);
    },
  },
  computed: {
    breadcrump() {
      return [
        {
          name: "Lista Monet",
          to: {
            name: "productlist",
          },
        },
        {
          name: this.product.metal == "gold" ? "Złoto" : "Srebro",
          to: {
            name: "productlist",
            query: {
              zloto: this.product.metal == "gold" ? true : false,
              srebro: this.product.metal == "silver" ? true : false,
            },
          },
        },
        {
          name: this.product.name,
          to: {
            name: "detail",
            params: {
              product: this.product.slug,
            },
          },
        },
      ];
    },
  },
  components: {
    Breadcrump,
    CompareWidget,
    PricingTable,
    ProductWidget,
    SideBySideCompareTable,
    SocialShareButtons,
    SpecificationPanel,
  },
};
</script>

<style scoped>
.subtitle {
  margin-top: 2rem;
}

.verify-button {
  margin-top: 2rem;
}
</style>
