<template>
  <section class="section content" style="border: 1px #fa7c91 dashed">
    <h3>
      <router-link
        :to="{name: 'article', params: {slug: 'jakie-monety-kupowac-w-2020'}}"
      >Najpopularniejsze złote monety bulionowe</router-link>
    </h3>
    <carousel
      :autoplay="true"
      :perPage="1"
      paginationActiveColor="#8a4d76"
      paginationColor="#fa7c91"
      :loop="true"
      :autoplayTimeout="2500"
    >
      <slide tagName="Krugerrand">
        <h4>1.</h4>

        <product-widget
          :product="{name:'Krugerrand', name_org: 'Krugerrand', slug: 'krugerrand', metal: 'gold', weight: '1oz', producer: {name: 'South African Mint'}, image_src_png: '/images/monety-small/krugerrand-1oz-gold.png', image_src_webp: '/images/monety-small/krugerrand-1oz-gold.webp', country: 'RPA'}"
        ></product-widget>
      </slide>
      <slide>
        <h4>2.</h4>
        <product-widget
          :product="{name:'Kanadyjski Liść Klonowy', name_org: 'Maple Leaf', slug: 'kanadyjski-lisc-klonowy', metal: 'gold', weight: '1oz', producer: {name: 'Royal Canadian Mint'}, image_src_png: '/images/monety-small/kanadyjski-lisc-klonowy-1oz-gold.png', image_src_webp: '/images/monety-small/kanadyjski-lisc-klonowy-1oz-gold.webp', country: 'Kanada'}"
        ></product-widget>
      </slide>
      <slide>
        <h4>3.</h4>
        <product-widget
          :product="{name:'Wiedeńscy Filharmonicy', name_org: 'Wiener Philharmoniker', slug: 'wiedenscy-filharmonicy', metal: 'gold', weight: '1oz', producer: {name: 'Münze Österreich'}, image_src_png: '/images/monety-small/wiedenscy-filharmonicy-1oz-gold.png', image_src_webp: '/images/monety-small/wiedenscy-filharmonicy-1oz-gold.webp', country: 'Austria'}"
        ></product-widget>
      </slide>
      <slide>
        <h4>4.</h4>
        <product-widget
          :product="{name:'Australijski Kangur', name_org: 'Australian Gold Kangaroo', slug: 'australijski-kangur', metal: 'gold', weight: '1oz', producer: {name: 'Perth Mint'}, image_src_png: '/images/monety-small/australijski-kangur-1oz-gold.png', image_src_webp: '/images/monety-small/australijski-kangur-1oz-gold.webp', country: 'Australia'}"
        ></product-widget>
      </slide>
      <slide>
        <h4>5.</h4>
        <product-widget
          :product="{name:'Amerykański Orzeł', name_org: 'American Eagle', slug: 'amerykanski-orzel', metal: 'gold', weight: '1oz', producer: {name: 'United States Mint'}, image_src_png: '/images/monety-small/amerykanski-orzel-1oz-gold.png', image_src_webp: '/images/monety-small/amerykanski-orzel-1oz-gold.webp', country: 'USA'}"
        ></product-widget>
      </slide>
    </carousel>
    <router-link :to="{name: 'article', params: {slug: 'jakie-monety-kupowac-w-2020'}}">
      <strong>zobacz jakie monety kupować →</strong>
    </router-link>
    <br />
    <small>(dane dzięki współpracy z: Mennica Gdańska, Mennica Polska, Mennica Skarbowa)</small>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
};
</script>
