<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <div class="content">
      <div id="socialWindow">
        <h1
          class="title is-size-3 has-text-centered"
        >Jak sprawdzić czy moneta {{ computedProduct.name }} jest prawdziwa?</h1>

        <div class="columns is-vcentered is-gapless is-mobile">
          <div class="column is-1-tablet">
            <specification-panel :product="computedProduct" :variant="currentVariant"></specification-panel>
          </div>

          <div class="column"></div>
          <div class="column">
            <figure class="image is-256x256">
              <picture>
                <source type="image/webp" :srcset="computedProduct.image_src_webp" />
                <img :src="computedProduct.image_src_png" />
              </picture>
            </figure>
          </div>
          <div class="column"></div>
        </div>
      </div>
      <p>
        <strong>Jak sprawdzić czy moneta jest fałszywa? Jak potwierdzić autetyczność monety bulionowej? Poniżej znajduje się opis czynności które potwierdzają autentyczność monety inwestycyjnej.</strong>
      </p>
      <h2>Skrócony spis czynności</h2>
      <p>Nie musisz przeprowadzać wszystkich kroków, jednak wtedy masz największą pewność, że moneta nie jest fałszywa.</p>
      <p>Czynności są ułożone w kolejności w której najpowszechniejsze przyrządy pomocnicze znajdują się na początku. Przecież każdy ma smartfona a już nie każdy wagę jubilerską 😄</p>
      <ol>
        <li>Oceń monetę wizualnie (smartfon).</li>
        <li>Przeprowadź próbę dźwiękową (smartfon).</li>
        <li v-if="computedProduct.metal=='silver'">Sprawdź reakcję na kostkę lodu.</li>
        <li>Sprawdź reakcję na magnes.</li>
        <li>Zmierz średnicę i grubość (suwmiarka).</li>
        <li>Zważ monetę (waga jubilerska).</li>
        <li>Przeprowadź test ciecią probiercza (testery jubilerskie)</li>
      </ol>
      <social-share-buttons :url="urlContent" :title="title"></social-share-buttons>

      <div class="content">
        <h2 id="ocen-monete-wizualnie">1. Oceń monetę wizualnie</h2>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>Smartfon z dostępem do Internetu</li>
        </ul>
        <blockquote>
          <span class="icon">
            <i class="fa fa-tools"></i>
          </span>
          Pracujemy nad tym by zbudować bazę danych dokładnych zdjęć ze wszystkich lat. Jeśli posiadasz {{ computedProduct.metal == 'gold'? 'Złoty' : 'Srebrny' }} {{ computedProduct.name }} {{ computedProduct.weight}},
          <a
            href="mailto:zbierajmonety@wonsystudio.com?subject=Mam zdjęcia monety"
            class="is-link"
          >skontakuj się z nami</a>.
        </blockquote>
        <strong>Instrukcja</strong>
        <ul>
          <li>Sprawdź z którego roku jest moneta</li>
          <li>Wyszukaj w Internecie zdjęć monety z tego konkretnego roku.</li>
          <li>
            Najlepiej by zdjęcia pochodziły od producenta, czyli
            <strong>{{computedProduct.producer.name}}</strong>.
          </li>
        </ul>
      </div>

      <div class="content">
        <h2 id="przeprowadz-probe-dzwiekowa">2. Przeprowadź próbę dźwiękową</h2>
        <p>Po uderzeniu w monetę, moneta zacznie drgać mocniej w pewnych charakterysycznych dla siebie częstotliowościach. Są to tak zwane częstotliwości rezonansowe. Ich dokładna wartość wynika z budowy monety.</p>
        <blockquote v-if="!hasFrequencesInfo" class="message is-warning">
          <span class="icon">
            <i class="fa fa-exclamation-circle"></i>
          </span>
          Uwaga! Nie posiadamy jeszcze danych o częstotliwościach {{ computedProduct.metal == 'gold'? 'Złoty' : 'Srebrny' }} {{ computedProduct.name }} {{ computedProduct.weight}}. Pracujemy nad tym by zbudować bazę danych dokładnych nagrań dźwiękowych monet ze wszystkich lat. Jeśli posiadasz tę monetę,
          <a
            href="mailto:info@wonsystudio.com"
            class="is-link"
          >skontakuj się z nami</a>.
        </blockquote>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>Smartfon z dostępem do Internetu i działającym mikrofonem</li>
        </ul>
        <strong>Instrukcja</strong>
        <ul>
          <li>Naciśnij "Włącz mikrofon" i zezwól na dostęp do mikrofonu w przeglądarce.</li>
          <li>Umieść monetę na swoim palcu.</li>
          <li>Uderz monetę paznokciem, długopisem lub inną monetą.</li>
          <li v-if="hasFrequencesInfo">
            Oczekiwane częstotliwości dla monety "{{ computedProduct.name }}" o wadze {{ computedProduct.weight }} to:
            <ul>
              <li v-for="freq in computedProduct.frequences" :key="freq">{{ freq }} Hz</li>
            </ul>
          </li>
        </ul>
        <ping-sound-analyzer :product="computedProduct"></ping-sound-analyzer>
      </div>

      <div v-if="computedProduct.metal == 'silver'" class="content">
        <h2 id="sprawdz-reakcje-na-kostke-lodu">3. Sprawdź reakcję na kostkę lodu.</h2>
        <p>Srebro jest znane ze swojej doskonałej przewodności cieplnej. Robi to lepiej niż powrzechnie używana do tego miedź.</p>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>Kostkę lodu</li>
        </ul>
        <strong>Instrukcja</strong>
        <ul>
          <li>Połóż monetę na równej powierzchni.</li>
          <li>
            Połóż kostkę lodu na monetę.
            <strong>Powinna się od razu zacząć topić.</strong>
          </li>
        </ul>
      </div>

      <div class="content">
        <h2
          v-if="computedProduct.metal == 'silver'"
          id="przeprowadz-probe-dzwiekowa"
        >4. Sprawdź reakcję na magnes</h2>
        <h2 v-else id="sprawdz-reakcje-na-magnes">3. Sprawdź reakcję na magnes</h2>
        <p>Zarówno złoto jak i srebro nie są magnetyczne.</p>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>Magnes - im silniejszy tym lepiej wykryje domieszki metali nieszlachetnych.</li>
        </ul>
        <strong>Instrukcja</strong>
        <ul>
          <li>Upewnij się, że moneta nie jest przyciągana przez magnes.</li>
          <li>Moneta może być delikatnie odepchnięta jeśli użyje się wystarczająco mocnego magnesu.</li>
          <li>
            Jeśli magnes przyciąga monetę, to prawdobodonie zawiera domieszki tańszych metali jak kobalt, żelazo czy nikiel.
            <strong>{{ computedProduct.name }} nie ma takich domieszek więc moneta jest fałszywa</strong>.
          </li>
        </ul>
      </div>

      <div class="content">
        <h2
          v-if="computedProduct.metal == 'silver'"
          id="zmierz-srednice-i-grubosc"
        >5. Zmierz średnicę i grubość</h2>
        <h2 v-else id="zmierz-srednice-i-grubosc">4. Zmierz średnicę i grubość</h2>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>
            Suwmiarka, na przykład
            <a
              :href="'https://go.buybox.click/linkclick_4728_78?p1=' + computedProduct.name +'&url=https%3A%2F%2Fwww.empik.com%2Fsuwmiarka-elektroniczna-iso-trade-lcd-l-150-150-mm-iso-trade%2Cp1228156698%2Cdom-i-ogrod-p'"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >Suwmiarka elektroniczna ISO TRADE</a>.
          </li>
        </ul>
        <strong>Instrukcja</strong>
        <ul>
          <li>
            Zmierz suwmiarką średnicę, powinna wynosić maksymalnie
            <strong>{{ computedProduct.diameter }}.</strong>
          </li>
          <li>
            Zmierz suwmiarką grubość w najgrubszym miejscu monety. Grubość powinna wynosić maksymalnie
            <strong>{{ computedProduct.thickness }}</strong>.
          </li>
        </ul>
      </div>

      <div class="content">
        <h2 v-if="computedProduct.metal == 'silver'" id="zwaz-monete">6. Zważ monetę</h2>
        <h2 v-else id="zwaz-monete">5. Zważ monetę</h2>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>
            Waga jubilerska, na przykład
            <a
              :href="'https://go.buybox.click/linkclick_4728_78?p1='+ computedProduct.name + '&url=https%3A%2F%2Fwww.empik.com%2Fwaga-jubilerska-orion-11-3x8x1-5-cm%2Cp1187165951%2Cagd-p'"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >Waga jubilerska Orion</a>.
          </li>
        </ul>
        <strong>Instrukcja</strong>
        <ul>
          <li>Ustaw wagę na równym, gładkim podłożu.</li>
          <li>
            Zważ monetę. Upewnij się, że waga wskazuje
            <strong>{{computedProduct.weight_g}} gram</strong>.
          </li>
        </ul>
      </div>

      <div class="content">
        <h2 v-if="computedProduct.metal == 'silver'">7. Przeprowadź test ciecią probierczą</h2>
        <h2 v-else>6. Przeprowadź test ciecią probierczą</h2>
        <strong>Potrzebujesz</strong>
        <ul>
          <li>Ciecze probiercze jubilerskie</li>
          <li>Kamień probierczy</li>
        </ul>
        <blockquote class="message is-warning">
          <span class="icon">
            <i class="fa fa-exclamation-circle"></i>
          </span>Korzystając z cieczy musisz być bardzo ostrożny. Może trwale zmienić kolor testowanej monety.
        </blockquote>
        <strong>Instrukcja</strong>
        <br />
        <iframe
          width="560"
          height="500"
          src="https://www.youtube.com/embed/gKYHVSiDPOg"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../database.json";
import Breadcrump from "./Breadcrump";
import PingSoundAnalyzer from "./PingSoundAnalyzer";
import SocialShareButtons from "./SocialShareButtons";
import SpecificationPanel from "./SpecificationPanel";

export default {
  name: "VerifyProduct",
  data() {
    return {
      product: null,
      database: null,
      title: "ZbierajMonety.pl",
      description: "",
      imageSrc: "",
      urlContent: "",
      currentVariant: "1oz",
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.imageSrc },
        { property: "og:image:url", content: this.imageSrc },
        { property: "og:image:width", content: 650 * 2 },
        { property: "og:image:height", content: 341 * 2 },

        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ],
      title: this.title,
      description: this.description,
    };
  },
  watch: {
    product() {
      if (this.product != null) {
        const metalDescription =
          this.product.metal == "gold" ? "Złoty" : "Srebrny";

        let weight = "";

        if (this.computedProduct.weight != "1oz") {
          weight = " " + this.computedProduct.weight + " ";
        }

        this.title = `Jak sprawdzić autentyczność ${metalDescription} ${this.product.name}${weight}`;
        this.description = `Wykonaj wszystkie kroki by upewnić się, że moneta  ${metalDescription} ${this.product.name} ${this.computedProduct.weight} nie jest fałszywa.`;
        const currentAbsoluteUrl = new URL(window.location.href);
        const currentAbsoluteUrlSplitted = currentAbsoluteUrl.pathname.split(
          "/"
        );
        const endSlug =
          currentAbsoluteUrlSplitted[currentAbsoluteUrlSplitted.length - 2];
        this.imageSrc = `https://zbierajmonety.pl/img${this.$route.path}${endSlug}.png`;

        // this.urlContent = window.location.href;
        this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;
      }
    },
  },
  beforeMount() {
    // prepare database:

    this.database = {};
    for (var i in json) {
      var elem = json[i];
      this.database[`${elem.slug}-${elem.metal}`] = elem;
    }
    let metal = this.$route.params.metal == "zloto" ? "gold" : "silver";
    this.currentVariant = this.$route.params.weight.replace("_", "/");
    this.product = this.database[`${this.$route.params.slug}-${metal}`];
  },
  components: {
    Breadcrump,
    PingSoundAnalyzer,
    SocialShareButtons,
    SpecificationPanel,
  },
  computed: {
    hasFrequencesInfo() {
      if (
        this.computedProduct["frequences"] === undefined ||
        this.computedProduct["frequences"] === null
      ) {
        return false;
      }
      return true;
    },
    computedProduct() {
      return this.mergeProductWithVariant(this.product, this.currentVariant);
    },
    breadcrump() {
      return [
        {
          name: "Lista Monet",
          to: {
            name: "productlist",
          },
        },
        {
          name: this.product.metal == "gold" ? "Złoto" : "Srebro",
          to: {
            name: "productlist",
            query: {
              zloto: this.product.metal == "gold" ? true : false,
              srebro: this.product.metal == "silver" ? true : false,
            },
          },
        },
        {
          name: this.product.name,
          to: {
            name: "detail",
            params: {
              product: this.product.slug,
            },
          },
        },
        {
          name: "Zweryfikuj",
          to: {
            name: "verify",
            params: {
              product: this.product.slug,
            },
          },
        },
      ];
    },
  },
};
</script>

<style>
</style>
