<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <h1 class="title">Test dźwiękowy monety bulionowej</h1>
    <p>Po uderzeniu w monetę, moneta zacznie drgać mocniej w pewnych charakterysycznych dla siebie częstotliowościach. Są to tak zwane częstotliwości rezonansowe. Ich dokładna wartość wynika z budowy monety.</p>
    <div class="section">
      <product-choice-dropdown
        :products="products"
        :eventBus="dropdownEventBus"
      >Wybierz monetę do testu</product-choice-dropdown>
    </div>
    <product-widget v-if="productChosen" :product="productChosen" :variant="variantChosen"></product-widget>
    <div class="content">
      <blockquote v-if="!hasFrequencesInfo" class="message is-warning">
        <span class="icon">
          <i class="fa fa-exclamation-circle"></i>
        </span>Uwaga! Nie posiadamy jeszcze danych o częstotiwościach dla tej monety. Pracujemy nad tym by zbudować bazę danych dokładnych nagrań dźwiekowych monet ze wszystkich lat. Jeśli posiadasz tę monetę,
        <a
          href="mailto:info@wonsystudio.com"
          class="is-link"
        >skontakuj się z nami</a>.
      </blockquote>
      <br />
      <strong>Instrukcja</strong>
      <ol>
        <li>Naciśnij "Włącz mikrofon" i zezwól na dostęp do mikrofonu w przeglądarce.</li>
        <li>Umieść monetę na swoim palcu.</li>
        <li>Uderz monetę paznokciem, długopisem lub inną monetą.</li>
      </ol>
      <div v-if="productChosen !== null && hasFrequencesInfo">
        Oczekiwane częstotliwości dla monety "{{ productChosen.name }}" o wadze {{ variantChosen }} to:
        <ul>
          <li v-for="freq in mergedProduct.frequences" :key="freq">{{ freq }} Hz</li>
        </ul>
      </div>
      <br />
      <ping-sound-analyzer :product="productChosen"></ping-sound-analyzer>
      <br />
      <div v-if="productChosen !== null">
        Test dźwiękowy to tylko jeden z wielu sposób na potwierdzenie autentyczności monety.
        <br />
        <router-link
          :to="{name: 'verify', params: {slug: productChosen.slug, weight: variantChosen.replace('/', '_'), metal: productChosen.metal == 'gold' ? 'zloto' : 'srebro'}}"
          class="is-link"
        >
          <strong>Przeprowadź pełną weryfikację "{{productChosen.name}}"→</strong>
        </router-link>
      </div>
    </div>
    <social-share-buttons :url="urlContent" :title="title"></social-share-buttons>
  </div>
</template>

<script>
import Vue from "vue";

import Breadcrump from "./Breadcrump";
import database from "../database.json";
import PingSoundAnalyzer from "./PingSoundAnalyzer";
import ProductChoiceDropdown from "./ProductChoiceDropdown";
import ProductWidget from "./ProductWidget";
import SocialShareButtons from "./SocialShareButtons";

export default {
  name: "SoundTestPage",
  data() {
    return {
      products: database.sort(
        (product1, product2) => product1.name > product2.name
      ),
      productChosen: null,
      title: "Darmowy test dźwiekowy monety bulionowej",
      description:
        "Wykonaj darmowy test dźwiekowy dla dowolnej monety bulionowej w bazie danych zbierajmonety.pl",
      urlContent: "https://zbierajmonety.pl/test/",
      variantChosen: "1oz",
      imageSrc: "#",
      dropdownEventBus: new Vue()
    };
  },
  computed: {
    hasFrequencesInfo() {
      if (this.productChosen) {
        if (
          this.mergedProduct &&
          (this.mergedProduct["frequences"] === undefined ||
            this.mergedProduct["frequences"] === null)
        ) {
          return false;
        }
      }
      return true;
    },
    mergedProduct() {
      if (this.productChosen) {
        return this.mergeProductWithVariant(
          this.productChosen,
          this.variantChosen
        );
      }
      return null;
    },
    breadcrump() {
      return [
        {
          name: "Test dźwiękowy",
          to: {
            name: "test"
          }
        }
      ];
    }
  },
  mounted() {
    var self = this;
    this.dropdownEventBus.$on(
      "PRODUCT_CHOSEN",
      product => (self.productChosen = product)
    );
    this.dropdownEventBus.$on(
      "VARIANT_CHOSEN",
      weight => (self.variantChosen = weight)
    );
  },
  components: {
    ProductChoiceDropdown,
    PingSoundAnalyzer,
    ProductWidget,
    SocialShareButtons,
    Breadcrump
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.imageSrc },
        { property: "og:image:url", content: this.imageSrc },
        { property: "og:image:width", content: 650 * 2 },
        { property: "og:image:height", content: 341 * 2 },

        { property: "og:image:alt", content: this.title },
        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" }

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ],
      title: this.title,
      description: this.description
    };
  }
};
</script>
