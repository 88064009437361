<template>
  <div>
    <breadcrump :breadcrump="breadcrump"></breadcrump>
    <h1 class="title">Lista monet</h1>
    <div class="section">
      <div class="columns is-desktop">
        <div class="column">
          <div class="field has-addons">
            <div class="control">
              <input class="input" type="text" placeholder="np. Bizon" v-model.trim="searchInput" />
            </div>
            <div class="control">
              <a class="button is-primary">Szukaj</a>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <label class="checkbox">
            <input type="checkbox" v-model="filterIncludeGold" />
            Złoto
          </label>
        </div>
        <div class="column is-3">
          <label class="checkbox">
            <input type="checkbox" v-model="filterIncludeSilver" />
            Srebro
          </label>
        </div>
      </div>
    </div>

    <product-widget
      v-for="product in sortedProducts"
      :key="product.slug + product.metal"
      :product="product"
    ></product-widget>
  </div>
</template>

<script>
import json from "../database.json";
import ProductWidget from "./ProductWidget";
import Breadcrump from "./Breadcrump";

export default {
  data() {
    return {
      products: json,
      searchInput: "",
      filterIncludeGold: true,
      filterIncludeSilver: true,
      breadcrump: [
        {
          name: "Lista Monet",
          to: {
            name: "productlist",
          },
        },
      ],
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.description },

        // Schema.org for Google
        { itemprop: "name", content: this.title },
        { itemprop: "description", content: this.description },
        { itemprop: "image", content: this.imageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.title },
        { name: "twitter:description", content: this.description },
        { name: "twitter:image:src", content: this.imageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        // { property: "og:image", content: this.imageSrc },
        // { property: "og:image:url", content: this.imageSrc },
        // { property: "og:image:width", content: 650 * 2 },
        // { property: "og:image:height", content: 341 * 2 },
        // { property: "og:image:alt", content: this.title },

        { property: "og:url", content: this.urlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },

        // Open Graph - Product (e-commerce)
        // { property: 'product:price:currency', content: 'PLN'},
        // { property: 'product:price:amount', content: '6337.16'},
        // { property: 'product:brand', content: 'United States Mint'},
      ].concat(this.metaImages),
      title: this.title,
      description: this.description,
    };
  },
  computed: {
    description() {
      let metal = "";
      if (this.filterIncludeGold && !this.filterIncludeSilver) {
        metal = "złotych ";
      } else if (this.filterIncludeSilver && !this.filterIncludeGold) {
        metal = "srebrnych ";
      }
      return `Lista wszystkich ${metal}monet lokacyjnych w bazie danych`;
    },
    title() {
      let metal = "";
      if (this.filterIncludeGold && !this.filterIncludeSilver) {
        metal = "złotych ";
      } else if (this.filterIncludeSilver && !this.filterIncludeGold) {
        metal = "srebrnych ";
      }
      return `Lista ${metal}monet bulionowych`;
    },
    sortedProducts() {
      var toReturn = this.products;

      var self = this;
      if (this.searchInput != "") {
        toReturn = toReturn.filter((item) =>
          item.name.toLowerCase().includes(self.searchInput.toLowerCase())
        );
      }

      if (!this.filterIncludeGold) {
        toReturn = toReturn.filter((item) => item.metal == "silver");
      }

      if (!this.filterIncludeSilver) {
        toReturn = toReturn.filter((item) => item.metal == "gold");
      }

      toReturn = toReturn.sort(
        (product1, product2) => product1.name > product2.name
      );

      return toReturn;
    },
    metaImages() {
      var images = [];
      for (var idx in this.products.slice(0, 4)) {
        var product = this.products[idx];
        // NOTE(sylwekb): hardcoded route :/
        images.push({
          property: "og:image",
          content: `https://zbierajmonety.pl/img/monety/${product.slug}/${product.slug}.png`,
        });
        images.push({
          property: "og:image:url",
          content: product.image_src_png,
        });
        images.push({ property: "og:image:width", content: 650 * 2 });
        images.push({ property: "og:image:height", content: 341 * 2 });
        images.push({
          property: "og:image:alt",
          content: this.title + ` ${idx}`,
        });
      }
      return images;
    },
  },
  components: {
    ProductWidget,
    Breadcrump,
  },
  watch: {
    filterIncludeGold(oldValue, newValue) {
      var query = Object.assign({}, this.$route.query);
      query["zloto"] = this.filterIncludeGold;
      query["srebro"] = this.filterIncludeSilver;
      this.$router.replace({ query });
    },
    filterIncludeSilver(oldValue, newValue) {
      var query = Object.assign({}, this.$route.query);
      query["zloto"] = this.filterIncludeGold;
      query["srebro"] = this.filterIncludeSilver;
      this.$router.replace({ query });
    },
  },
  mounted() {
    this.filterIncludeGold =
      this.$route.query.zloto == "false" || this.$route.query.zloto === false
        ? false
        : true;
    this.filterIncludeSilver =
      this.$route.query.srebro == "false" || this.$route.query.srebro == false
        ? false
        : true;
    const currentAbsoluteUrl = new URL(window.location.href);
    this.urlContent = `https://zbierajmonety.pl${currentAbsoluteUrl.pathname}`;
  },
};
</script>
